import { useState } from "react";

import styled from "styled-components";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";

import { getDeviceType } from "components/helper/deviceService";
import { DEVICE_TYPE } from "types/deviceType";
import { CSS_VARIABLES } from "enums/cssVariables";

import Downloader from "components/Downloader";
import OnBoarding from "components/OnBoarding";

interface StyledWrapperSnackBarProps {
    isVisible: boolean;
}

const InstallButtonWrapper = () => {
    const [trigger, setTrigger] = useState(0);
    const [snackBarIsVisible, setSnackBarIsVisible] = useState<boolean>(true);

    const i18n = useTranslation();

    const watchMedia = window.matchMedia("(display-mode: browser)");
    const [isApplicationRunningOnBrowser, setIsApplicationRunningOnBrowser] =
        useState<boolean>(watchMedia.matches);

    const renderSwitch = (): JSX.Element => {
        switch (getDeviceType()) {
            case DEVICE_TYPE.BROWSER_COMPATIBLE:
                return <Downloader trigger={trigger}></Downloader>;
            default:
                return <OnBoarding trigger={trigger}></OnBoarding>;
        }
    };

    const renderInstallBanner = () => {
        //When app runs as application, window display mode is defined as "standalone" instead of "browser"
        //In this case it does'nt make sens to show the install button

        //When switching from browser to application
        //We want to make sur to be up to date
        watchMedia.addEventListener("change", (e) => {
            setIsApplicationRunningOnBrowser(e.matches);
        });

        const handleClose = () => {
            setSnackBarIsVisible(false);
        };

        return (
            (isApplicationRunningOnBrowser && (
                <div>
                    <StyledWrapperSnackBar isVisible={snackBarIsVisible}>
                        <IconWrapper onClick={handleClose}>
                            <CloseIcon />
                        </IconWrapper>
                        <StyledMessageSnackbar>
                            <h3> {`${i18n.t("optimizeExperience")}`}</h3>
                            <p> {`${i18n.t("installApp")}`}</p>
                        </StyledMessageSnackbar>
                        <StyledButtonSnackBar
                            onClick={() => {
                                setTrigger((trigger) => trigger + 1);
                            }}
                        >
                            {`${i18n.t("install")}`}
                        </StyledButtonSnackBar>
                        {renderSwitch()}
                    </StyledWrapperSnackBar>
                </div>
            )) ||
            null
        );
    };

    return renderInstallBanner();
};

const StyledWrapperSnackBar = styled.div<StyledWrapperSnackBarProps>`
    display: ${({ isVisible }) => (isVisible ? "flex" : "none")};
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background-color: var(${CSS_VARIABLES.PRIMARY_BUTTON_BACKGROUND_COLOR});
    position: absolute;
    bottom: 5rem;
    left: 50%;
    transform: translate(-50%, -50%);
    justify-content: space-between;
    @media (max-width: 450px) {
        width: 100%;
        bottom: 0;
        left: 0;
        transform: translate(0, 0);
    }
`;

const IconWrapper = styled.div`
    margin: 10px 15px 10px 15px;
    > svg {
        color: #ffff;
    }
    :hover {
        cursor: pointer;
        filter: brightness(0.8);
    }
`;

const StyledMessageSnackbar = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #ffff;
    > h3 {
        font-size: 14px;
        margin: 15px 0 7px 0;
        width: 100%;
    }
    > p {
        font-size: 14px;
        margin: 0 0 15px 0;
        width: 100%;
    }
`;

const StyledButtonSnackBar = styled.button`
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    background-color: #ffff;
    height: 40px;
    margin: 10px 15px 10px 15px;
    border: 1px solid transparent;
    color: grey;

    :hover {
        cursor: pointer;
        filter: brightness(0.8);
    }
    :focus-visible {
        outline: solid 3px var(${CSS_VARIABLES.PRIMARY_BUTTON_BACKGROUND_COLOR});
    }
`;

export default InstallButtonWrapper;
