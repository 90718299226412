import { ReactElement, useState } from "react";
import styled from "styled-components";

import Header from "./Header";
import Footer from "./Footer/Footer";
import BannerInformation from "./BannerInformation";

type Props = {
    children: ReactElement;
    isSocketError?: boolean;
};

const Layout = ({ children, isSocketError }: Props) => {
    const [isOffline, setIsOffline] = useState(false);

    window.addEventListener("offline", () => {
        setIsOffline(true);
    });

    window.addEventListener("online", () => {
        setIsOffline(false);
    });

    let bannerMessage;

    if (isOffline) {
        bannerMessage = "offlineMessage";
    } else if (isSocketError) {
        bannerMessage = "socketConnectError";
    }

    return (
        <>
            {bannerMessage ? (
                <BannerInformation message={bannerMessage} />
            ) : null}
            <Wrapper>
                <Header />
                <div>{children}</div>
                <Footer />
            </Wrapper>
        </>
    );
};

const Wrapper = styled.div`
    max-width: 620px;
    margin: auto;
    min-height: calc(var(--vh, 1vh) * 100);
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
        max-width: 350px;
    }
`;

export default Layout;
