export enum CSS_VARIABLES {
    PRIMARY_TEXT_COLOR = "--primary-text-color",
    BACKGROUND_FORM_COLOR = "--background-form-color",
    PRIMARY_BUTTON_TEXT_COLOR = "--primary-btn-text-color",
    PRIMARY_BUTTON_BACKGROUND_COLOR = "--primary-btn-bg-color",
    PRIMARY_BUTTON_BORDER_COLOR = "--primary-btn-border-color",
    LOGO_ALIGNMENT = "--logo-alignment",
    LOGO_URL = "--logo-url",
    LOGO_MAX_HEIGHT = "--logo-height",
    INSTALLATION_BUTTON_DISPLAY = "--installation-display",
    BOX_SHADOW_PIXEL = "--box-shadow-pixel",
    BORDER_FORM_COLOR = "--border-form-color",
}
