import { useState } from "react";
import styled from "styled-components";

import { VoteStatus } from "types/voteEnum";

import OptionsWrapper from "./OptionsWrapperDnD";
import ActionSection from "../../../common/ActionSection";
import TitleHeader from "../../../common/TitleHeader";

type Props = {
    onValidate: (result: string) => void;
    options: Array<{
        id: string;
        text: string;
    }>;
    title: string;
};

const OrderedQuestion = ({ title, options, onValidate }: Props) => {
    const [listOptions, setListOptions] = useState(options);
    const [voteStatus, setVoteStatus] = useState(VoteStatus.PendingValidation);

    const handleValidate = () => {
        const mapListAnswers = listOptions.map((state) => state.id);
        const answer = mapListAnswers.join("");
        onValidate(answer);
        setVoteStatus(VoteStatus.Success);
    };

    const handleReset = () => {
        setVoteStatus(VoteStatus.PendingValidation);
    };

    return (
        <WrapperVote>
            <TitleHeader title={title} />
            <OptionsWrapper
                options={listOptions}
                setOptions={setListOptions}
                setVoteStatus={setVoteStatus}
            />
            <ActionSection
                handleValidate={handleValidate}
                handleReset={handleReset}
                voteStatus={voteStatus}
            />
        </WrapperVote>
    );
};

const WrapperVote = styled.div`
    width: 100%;
    margin: auto;
    @media (max-width: 768px) {
        max-width: 350px;
    }
`;

export default OrderedQuestion;
