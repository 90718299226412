import styled from "styled-components";
import { BaseProposal } from "./BaseProposal";
import { CSS_VARIABLES } from "enums/cssVariables";

type Props = {
    text: string;
    isActive: boolean;
    isDisabled?: boolean;
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const BasicProposalButton = ({
    text,
    isActive,
    isDisabled,
    onClick,
}: Props) => {
    return (
        <StyledButton
            tabIndex={0}
            disabled={isDisabled}
            onClick={onClick}
            isActive={isActive}
        >
            <CheckboxContainer>
                <HiddenCheckbox />
                <StyledCheckbox isChecked={isActive}>
                    <Icon viewBox="0 0 24 24" isActive={isActive}>
                        <polyline points="20 6 9 17 4 12" />
                    </Icon>
                </StyledCheckbox>
            </CheckboxContainer>
            <span>{text}</span>
        </StyledButton>
    );
};

interface StyledButtonProps {
    isActive: boolean;
}

interface StyledCheckBoxProps {
    isChecked: boolean;
}

const StyledButton = styled(BaseProposal)<StyledButtonProps>`
    background: ${(props) =>
        props.isActive
            ? `var(${CSS_VARIABLES.PRIMARY_BUTTON_BACKGROUND_COLOR})`
            : null};
    color: ${(props) =>
        props.isActive
            ? `var(${CSS_VARIABLES.PRIMARY_BUTTON_TEXT_COLOR})`
            : `var(${CSS_VARIABLES.PRIMARY_BUTTON_BACKGROUND_COLOR})`};
    border: ${(props) =>
        props.isActive
            ? `1px solid var(${CSS_VARIABLES.PRIMARY_BUTTON_TEXT_COLOR})`
            : `1px solid var(${CSS_VARIABLES.PRIMARY_BUTTON_BACKGROUND_COLOR})`};
    display: flex;
    align-items: center;
    &[disabled] {
        background: ${(props) =>
            props.isActive
                ? `var(${CSS_VARIABLES.PRIMARY_BUTTON_BACKGROUND_COLOR})`
                : "#e8e8e8"};
        opacity: 0.7;
        cursor: not-allowed;
    }
    > span {
        padding: 0.75rem 0 0.75rem 0.75rem;
        word-break: break-word;
        text-align: left;
    }
`;

const CheckboxContainer = styled.div`
    display: flex;
`;

const Icon = styled.svg<StyledButtonProps>`
    fill: none;
    stroke: ${(props) =>
        props.isActive
            ? `var(${CSS_VARIABLES.PRIMARY_BUTTON_BACKGROUND_COLOR})`
            : null};
    stroke-width: 5px;
    background: ${(props) => (props.isActive ? "#FFFFFF" : null)};
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
    display: none;
    &:focus {
        outline: none !important;
    }
`;

const StyledCheckbox = styled.div<StyledCheckBoxProps>`
    border: ${(props) =>
        props.isChecked
            ? `1px solid var(${CSS_VARIABLES.PRIMARY_BUTTON_TEXT_COLOR})`
            : `1px solid var(${CSS_VARIABLES.PRIMARY_BUTTON_BACKGROUND_COLOR})`};
    width: 16px;
    height: 16px;
    border-radius: 2px;
    transition: all 150ms;
    background-color: #ffffff;
`;

export default BasicProposalButton;
