import styled from "styled-components";

export const Input = styled.input`
    width: 100%;
    height: 40px;
    padding: 6px 12px;
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    border: 1px solid #ccc;
    box-sizing: border-box;
    &:focus {
        border-color: #66afe9;
        outline: 0;
        -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%),
            0 0 8px rgb(102 175 233 / 60%);
    }
`;
