import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import DoneIcon from "@mui/icons-material/Done";

import { QuestionType } from "types/questionTypes";

import Button from "../Button/Button";
import ValidateButton from "../Button/ValidateButton";
import ResetButton from "../Button/ResetButton";
import ArrowButton from "../Button/ArrowButton";

type Props = {
    handleValidate: () => void;
    onReset: () => void;
    onButtonClick: (value: string) => void;
    remoteMaxNumberButton: number;
    onArrowUpClick?: (value: string) => void;
    onArrowDownClick?: (value: string) => void;
    typeVote?: string;
};

const KeyboardVotebox = ({
    handleValidate,
    onReset,
    onButtonClick,
    remoteMaxNumberButton,
    onArrowDownClick,
    onArrowUpClick,
    typeVote,
}: Props) => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <Row>
                <ValidateButton
                    handleValidate={handleValidate}
                    aria-label={t("remote.validate")}
                    color={
                        typeVote === QuestionType.DELIBERATION
                            ? "#607d91"
                            : undefined
                    }
                >
                    {typeVote === QuestionType.DELIBERATION ? (
                        <StyledOK>OK</StyledOK>
                    ) : (
                        <DoneIcon style={{ fontSize: "32px" }} />
                    )}
                </ValidateButton>
                <ResetButton
                    onReset={onReset}
                    aria-label={t("remote.reset")}
                    color={
                        typeVote === QuestionType.DELIBERATION
                            ? "#607d91"
                            : undefined
                    }
                />
            </Row>
            <Row>
                <Button
                    value={"1"}
                    onButtonClick={onButtonClick}
                    disabled={remoteMaxNumberButton < 1}
                    backgroundColor={
                        typeVote === QuestionType.DELIBERATION
                            ? "#00a900"
                            : undefined
                    }
                />
                <Button
                    value={"2"}
                    onButtonClick={onButtonClick}
                    disabled={remoteMaxNumberButton < 2}
                    backgroundColor={
                        typeVote === QuestionType.DELIBERATION
                            ? "#FF0000"
                            : undefined
                    }
                />
                <Button
                    value={"3"}
                    onButtonClick={onButtonClick}
                    disabled={remoteMaxNumberButton < 3}
                    backgroundColor={
                        typeVote === QuestionType.DELIBERATION
                            ? "#607d91"
                            : undefined
                    }
                />
            </Row>
            <Row>
                <Button
                    value={"4"}
                    onButtonClick={onButtonClick}
                    disabled={remoteMaxNumberButton < 4}
                />
                <Button
                    value={"5"}
                    onButtonClick={onButtonClick}
                    disabled={remoteMaxNumberButton < 5}
                />
                <Button
                    value={"6"}
                    onButtonClick={onButtonClick}
                    disabled={remoteMaxNumberButton < 6}
                />
            </Row>
            <Row>
                <Button
                    value={"7"}
                    onButtonClick={onButtonClick}
                    disabled={remoteMaxNumberButton < 7}
                />
                <Button
                    value={"8"}
                    onButtonClick={onButtonClick}
                    disabled={remoteMaxNumberButton < 8}
                />
                <Button
                    value={"9"}
                    onButtonClick={onButtonClick}
                    disabled={remoteMaxNumberButton < 9}
                />
            </Row>
            <Row>
                <ArrowButton
                    aria-label={t("remote.validate")}
                    disabled={onArrowUpClick === undefined}
                    direction={"up"}
                    onClick={onArrowUpClick}
                />
                <Button
                    value={"0"}
                    onButtonClick={onButtonClick}
                    disabled={remoteMaxNumberButton < 10}
                />
                <ArrowButton
                    aria-label={t("remote.validate")}
                    disabled={onArrowDownClick === undefined}
                    direction={"down"}
                    onClick={onArrowDownClick}
                />
            </Row>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
`;

const Row = styled.div`
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    padding: 0 5px;
`;

const StyledOK = styled.p`
    margin: 0;
    font-weight: 700;
`;

export default KeyboardVotebox;
