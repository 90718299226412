import React, { useRef } from "react";
import styled from "styled-components";

import { VoteStatus } from "../Deliberation/useVoteState";

import DistributionCaseProposal from "./Buttons/DistributionCaseProposal";

type Proposal = {
    disableOtherPropositionsIfSelected: boolean;
    id: string;
    shortText: string;
    isVisibleByDefault: boolean;
};

type Props = {
    propositions: Array<Proposal>;
    answers: Array<{
        id: string;
        weight: number;
    }>;
    isSecret: boolean;
    voterWeight: number;
    voteStatus: string;
    updateWeight: (id: string, weight: number) => void;
    proposalsOrientation: string;
};

interface StyledButtonProps {
    proposalsOrientation: string;
}

const VoteDistribution = ({
    propositions,
    answers,
    isSecret,
    voterWeight,
    voteStatus,
    updateWeight,
    proposalsOrientation,
}: Props) => {
    const inputRefs = useRef<Array<HTMLInputElement>>([]);

    const remainingWeight = answers
        .map((answer) => answer.weight)
        .reduce(
            (previousValue, currentValue) => previousValue - currentValue,
            voterWeight
        );

    const renderProposals = () => {
        const filteredInputRefs = inputRefs.current.filter(
            (ref) => ref !== null
        );

        const focusNextInput = (currentInputId: string) => {
            const currentProposal = propositions.find(
                (proposal) => proposal.id === currentInputId
            );

            if (!currentProposal) {
                return;
            }
            const indexOfCurrentProposal =
                propositions.indexOf(currentProposal);

            const nextInput =
                filteredInputRefs[
                    indexOfCurrentProposal === filteredInputRefs.length - 1
                        ? 0
                        : indexOfCurrentProposal + 1
                ];

            nextInput.focus();
        };

        return propositions.map((proposition, item) => {
            const handleBlur = (inputValue: number) => {
                const currentAnswerWeight = answers.find(
                    (answer) => answer.id === proposition.id
                )?.weight as number;

                const newWeight =
                    inputValue >= remainingWeight + currentAnswerWeight
                        ? remainingWeight + currentAnswerWeight
                        : inputValue;

                updateWeight(proposition.id, newWeight);
            };

            const isActive =
                VoteStatus.Success === voteStatus && isSecret
                    ? false
                    : answers.some(
                          (answer) =>
                              answer.id === proposition.id && answer.weight > 0
                      );

            const isDisabled = () => {
                if (VoteStatus.Success === voteStatus) {
                    return true;
                }

                if (isActive) {
                    return false;
                }

                if (0 === remainingWeight) {
                    return true;
                }

                return false;
            };

            const renderColorBtn = () => {
                if ("1" === proposition.id) {
                    return "#60B860";
                } else if ("2" === proposition.id) {
                    return "#E10032";
                } else {
                    return "#737373";
                }
            };

            return (
                <DistributionCaseProposal
                    key={item}
                    isActive={isActive}
                    text={proposition.shortText}
                    isDisabled={isDisabled()}
                    remainingWeight={remainingWeight}
                    onBlur={handleBlur}
                    color={renderColorBtn()}
                    value={
                        answers.find((answer) => answer.id === proposition.id)
                            ?.weight
                    }
                    onKeyUp={(e) => {
                        if ("Enter" === e.key) {
                            focusNextInput(proposition.id);
                        }
                    }}
                    currentRef={(el) =>
                        (inputRefs.current[Number(proposition.id)] = el)
                    }
                />
            );
        });
    };

    return (
        <Wrapper>
            {/* In case of proxies, the position of proposals is horizontal */}
            <StyledProposals proposalsOrientation={proposalsOrientation}>
                {renderProposals()}
            </StyledProposals>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    margin-top: 7px;
`;

const StyledProposals = styled.div<StyledButtonProps>`
    display: ${(props) =>
        "horizontal" === props.proposalsOrientation ? "flex" : null};
    gap: ${(props) =>
        "horizontal" === props.proposalsOrientation ? "1rem" : null};
`;

export default VoteDistribution;
