import React from "react";
import styled from "styled-components";

import BasicProposalButton from "components/vote/Quizzbox/common/Buttons/BasicProposalButton";
import { Action } from "./ChoiceQuestion";

type Props = {
    options: Array<{
        id: string;
        text: string;
    }>;
    dispatchVoteAction: React.Dispatch<Action>;
    answers: Array<string>;
    voteStatus: string;
    maxNumberOfAnswers?: number;
};

const OptionsWrapper = ({
    options,
    dispatchVoteAction,
    answers,
    maxNumberOfAnswers,
}: Props) => {
    const renderOptions = () => {
        return options.map((option, item) => {
            const isActive = answers.some((answer) => answer === option.id);

            const isUniqueChoice = 1 === maxNumberOfAnswers;

            const isDisabled = () => {
                if (0 === answers.length) {
                    return false;
                }

                if (isUniqueChoice) {
                    return false;
                }

                if (isActive) {
                    return false;
                }

                return answers.length === maxNumberOfAnswers;
            };

            const onClickProposal = () => {
                if (isActive) {
                    dispatchVoteAction({
                        type: "deleteAnswer",
                        answer: option.id,
                    });
                    return;
                }

                if (isUniqueChoice) {
                    dispatchVoteAction({
                        type: "replaceAnswer",
                        answer: option.id,
                    });
                    return;
                }

                dispatchVoteAction({
                    type: "addAnswer",
                    answer: option.id,
                });
            };

            return (
                <BasicProposalButton
                    text={option.text}
                    isActive={isActive}
                    isDisabled={isDisabled()}
                    onClick={() => {
                        onClickProposal();
                    }}
                    key={item}
                />
            );
        });
    };
    return <Wrapper>{renderOptions()}</Wrapper>;
};

const Wrapper = styled.div`
    margin-top: 15px;
`;

export default OptionsWrapper;
