import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { VoteStatus } from "types/voteEnum";

import OptionButton from "./OptionButton";
import { CSS_VARIABLES } from "enums/cssVariables";

type Props = {
    options: Array<{
        id: string;
        text: string;
    }>;
    setOptions: React.Dispatch<
        React.SetStateAction<Array<{ id: string; text: string }>>
    >;
    setVoteStatus: React.Dispatch<React.SetStateAction<VoteStatus>>;
};

const OptionsWrapper = ({ options, setOptions, setVoteStatus }: Props) => {
    const [enabled, setEnabled] = useState(false);

    //fix the issue with react-beautiful-dnd and React in StrictMode was to render the Droppable elements after an animation frame
    useEffect(() => {
        const animation = requestAnimationFrame(() => setEnabled(true));

        return () => {
            cancelAnimationFrame(animation);
            setEnabled(false);
        };
    }, []);

    const onDragEnd = (result: any) => {
        const { destination, source } = result;

        if (!destination) {
            return;
        }
        if (
            destination.droppableId === source.draggableId &&
            destination.index === source.index
        ) {
            return;
        }
        const items = Array.from(options);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setOptions(items);
        setVoteStatus(VoteStatus.PendingValidation);
    };

    return (
        <Wrapper>
            <DragDropContext onDragEnd={onDragEnd}>
                {enabled === true ? (
                    <Droppable droppableId="droppable">
                        {(provided) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {options.map((option, index) => (
                                    <Draggable
                                        key={option.id}
                                        draggableId={option.id}
                                        index={index}
                                    >
                                        {(providerParam, snapshot) => (
                                            <OptionButton
                                                innerRef={
                                                    providerParam.innerRef
                                                }
                                                provided={providerParam}
                                                text={option.text}
                                                getStyle={getItemStyle(
                                                    snapshot.isDragging,
                                                    providerParam.draggableProps
                                                        .style
                                                )}
                                            ></OptionButton>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                ) : null}
            </DragDropContext>
        </Wrapper>
    );
};

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    background: isDragging
        ? `var(${CSS_VARIABLES.PRIMARY_BUTTON_BACKGROUND_COLOR})`
        : "",
    borderColor: isDragging ? "var(--primary-btn-border-color)" : "",
    color: isDragging ? "#fff" : "",
    ...draggableStyle,
});

const Wrapper = styled.div`
    margin-top: 15px;
`;

export default OptionsWrapper;
