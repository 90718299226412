import styled from "styled-components";
import { BaseButton } from "./BaseButton";

type Props = {
    value: string;
    disabled?: boolean;
    onButtonClick: (value: string) => void;
    backgroundColor?: string;
};

interface StyledButtonProps {
    backgroundColor: string | undefined;
}

const Button = ({
    value,
    disabled = false,
    onButtonClick,
    backgroundColor,
}: Props) => {
    return (
        <StyledButton
            onClick={() => onButtonClick(value)}
            disabled={disabled}
            backgroundColor={backgroundColor}
        >
            {value}
        </StyledButton>
    );
};

const StyledButton = styled(BaseButton)<StyledButtonProps>`
    box-shadow: ${(props) => props.theme.button.boxShadow};
    font-weight: ${(props) => props.theme.button.fontWeight};
    color: ${(props) =>
        props.backgroundColor ? "white" : props.theme.button.color};
    background-color: ${(props) =>
        props.backgroundColor ?? props.theme.backgroundColor};
    :hover {
        background: ${(props) =>
            props.backgroundColor ?? props.theme.button.hoverBackgroundColor};
        opacity: 0.5;
    }
    &[disabled] {
        background-color: #e8e8e8;
        cursor: not-allowed;
    }
`;

export default Button;
