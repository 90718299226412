import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { NotFoundError, UnknownError } from "services/errors";

export const useHandleErrors = () => {
    const i18n = useTranslation();

    const { enqueueSnackbar } = useSnackbar();
    const { setError: setFieldError } = useForm();

    const handleLoginError = (status: number) => {
        if (status === 401) {
            enqueueSnackbar(`${i18n.t("errorAuth")}`);
            setFieldError("idReference", { type: "manual" });
        } else if (status === 404) {
            throw new NotFoundError();
        } else {
            throw new UnknownError();
        }
    };

    const handleCurrentUserError = (status: number) => {
        if (status === 404) {
            throw new NotFoundError();
        } else {
            throw new UnknownError();
        }
    };

    const handleSessionError = (status: number) => {
        if (status === 401) {
            enqueueSnackbar(`${i18n.t("errorYouMustBeLog")}`, {
                variant: "error",
                persist: true,
            });
        } else if (status === 403) {
            enqueueSnackbar(`${i18n.t("error403")}`, {
                variant: "error",
                persist: true,
            });
        }
    };

    return { handleLoginError, handleCurrentUserError, handleSessionError };
};
