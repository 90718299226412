import { VoteStateGroupedDeliberation } from "../GroupedDeliberation/useVoteState";

const getAndFormatAnswers = (voteToSend: VoteStateGroupedDeliberation) => {
    return voteToSend.deliberations
        .flatMap((delib) =>
            delib.answers.map((answer) => ({
                ...answer,
                questionId: delib.idDeliberation,
            }))
        )
        .filter((answer) => answer.weight > 0)
        .map((answer) => ({
            questionId: answer.questionId,
            answer: answer.id,
            weight: answer.weight,
            disableOtherPropositionsIfSelected:
                answer.disableOtherPropositionsIfSelected,
        }));
};

export default getAndFormatAnswers;
