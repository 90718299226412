export type SessionType = {
    codeOnline: string;
    scenario: string;
    sessionName: string;
    displayMode: string;
    lang: string;
    telecommandeParticipantCSS: string;
    streaming: string;
    streamingLibelle: string;
    streamingLogin: string;
    streamingPassword: string;
    zoomSignature: string;
    isSignatureRequired: boolean;
};

export enum DisplayMode {
    QUESTION = "question",
    VIRTUAL_REMOTE = "virtualRemote",
}
