import { useTranslation } from "react-i18next";
import { useSession } from "components/helper/sessionContext";

import {
    loginFormScenario,
    getCurrentUser,
    getSessionInformation,
} from "components/helper/callApi";
import { useUser } from "components/helper/userContext";
import { NotFoundError, UnknownError } from "services/errors";
import { useHandleErrors } from "./handleErrors";
import { LS_AUTH_TOKEN_KEY } from "constants/constants";

import Form from "./Form";

type Props = {
    fields: Array<{
        name: string;
        placeholder: string;
        ariaLabel: string;
    }>;
    codeOnline: string;
    action: string;
    onError: (error: NotFoundError | UnknownError) => void;
};

const BasicForm = ({ fields, codeOnline, action, onError }: Props) => {
    const userContext = useUser();
    const i18n = useTranslation();
    const { setSession } = useSession();

    const { handleLoginError, handleCurrentUserError, handleSessionError } =
        useHandleErrors();

    const onSubmit = async (data: any) => {
        try {
            //Login request
            const loginResponse = await loginFormScenario(
                codeOnline,
                action,
                data
            );

            if (!loginResponse.ok) {
                handleLoginError(loginResponse.status);

                return;
            }

            const response = await loginResponse.json();

            if (response.token) {
                localStorage.setItem(LS_AUTH_TOKEN_KEY, response.token);
            }

            //Get the current user's information
            const currentUserResponse = await getCurrentUser();

            if (currentUserResponse.ok) {
                const userData = await currentUserResponse.json();
                userContext.setUser(userData);

                // Get session information
                const sessionResponse = await getSessionInformation(
                    codeOnline,
                    ["zoomSignature"]
                );
                if (sessionResponse.ok) {
                    const sessionData = await sessionResponse.json();
                    setSession(sessionData);
                } else {
                    handleSessionError(sessionResponse.status);
                }
            } else {
                handleCurrentUserError(currentUserResponse.status);
            }
        } catch (error) {
            onError(new UnknownError());
        }
    };

    return (
        <Form
            onSubmit={onSubmit}
            fields={fields}
            formMessage={i18n.t(`enterPin.${process.env.REACT_APP_THEME}`)}
            formButtonText={i18n.t("joinIn")}
        />
    );
};

export default BasicForm;
