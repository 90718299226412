import { createGlobalStyle } from "styled-components";
import { styles } from "assets/templateCSS";
import LogoMain from "assets/logo_main.png";
import { CSS_VARIABLES } from "enums/cssVariables";

export const buildGlobalStylesComponent = ({
    overrides,
}: {
    overrides?: string | null;
}) => createGlobalStyle`

body {
    display: flex;
    flex-direction: column;
    margin: 0;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

*{
    ${styles}
    ${CSS_VARIABLES.PRIMARY_BUTTON_TEXT_COLOR}: #ffffff;
    ${CSS_VARIABLES.PRIMARY_BUTTON_BORDER_COLOR}: #567183;
    ${CSS_VARIABLES.BACKGROUND_FORM_COLOR}: #fafafa;
    ${CSS_VARIABLES.LOGO_ALIGNMENT}: center;
    ${CSS_VARIABLES.LOGO_URL}: url(${LogoMain});
    ${CSS_VARIABLES.INSTALLATION_BUTTON_DISPLAY}: none;
    ${overrides || ""}
    ${CSS_VARIABLES.BOX_SHADOW_PIXEL}: ${
    overrides?.includes(CSS_VARIABLES.BACKGROUND_FORM_COLOR)
        ? "0px 0px 0px 2px;"
        : "0px 0px 3px 0px;"
}
    ${CSS_VARIABLES.BORDER_FORM_COLOR}: ${
    overrides?.includes(`${CSS_VARIABLES.BACKGROUND_FORM_COLOR}`)
        ? `var(${CSS_VARIABLES.BACKGROUND_FORM_COLOR});`
        : "rgb(0 0 0 / 35%);"
}
    ${CSS_VARIABLES.LOGO_MAX_HEIGHT}: ${
    overrides?.includes(`${CSS_VARIABLES.BACKGROUND_FORM_COLOR}`)
        ? "120px"
        : "57px"
}
}

`;
