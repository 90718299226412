import styled from "styled-components";
import { BaseButton } from "./BaseButton";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

type Props = {
    disabled?: boolean;
    onClick?: (value: any) => void;
    direction: string;
};

const ArrowButton = ({ onClick, disabled, direction }: Props) => {
    return (
        <StyledValidateButton
            onClick={onClick}
            tabIndex={0}
            disabled={disabled}
            data-testid={`btn-${direction}`}
        >
            {"up" === direction ? (
                <ArrowUpwardIcon style={{ fontSize: "32px" }} />
            ) : (
                <ArrowDownwardIcon style={{ fontSize: "32px" }} />
            )}
        </StyledValidateButton>
    );
};

const StyledValidateButton = styled(BaseButton)`
    box-shadow: ${(props) => props.theme.button.boxShadow};
    background-color: ${(props) => props.theme.validateButton.backgroundColor};
    color: ${(props) => props.theme.button.color};
    :hover {
        background-color: ${(props) =>
            props.theme.validateButton.hoverBackgroundColor};
    }
    &[disabled] {
        background-color: #e8e8e8;
        cursor: not-allowed;
    }
`;

export default ArrowButton;
