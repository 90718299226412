import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import {
    reverseSessionSubdomain,
    getSubdomainFromSession,
    getCustomization,
} from "components/helper/callApi";
import {
    formatCssVariablesFromCustomization,
    getCustomizationLocalStorageWithExpiry,
    setCustomizationLocalStorageWithExpiry,
    CustomizationProps,
} from "services/customizationService";
import { getSubdomain } from "services/urlService";
import { ERROR } from "types/snackBar.constant";
import { useSubdomain } from "components/helper/SubdomainContext";

interface Customization {
    subdomain: string;
    customization: CustomizationProps;
}

const SubdomainHandler = ({ children }: any) => {
    const { enqueueSnackbar } = useSnackbar();
    const i18n = useTranslation();
    const navigate = useNavigate();
    const { setIsDone } = useSubdomain();

    const subdomain = getSubdomain();
    const currentPath = window.location.pathname;

    const handleReverseSubdomain = async () => {
        try {
            const res = await reverseSessionSubdomain();
            if (res.ok) {
                const data = await res.json();
                navigate(`/login/${data.codeOnline}`);
            }
        } catch (error) {
            enqueueSnackbar(`${i18n.t("errorUnknown")}`, {
                variant: ERROR,
            });
        }
    };

    const retrieveSubdomainToRedirectFromSession = async (
        codeOnline: string
    ) => {
        try {
            const res = await getSubdomainFromSession(codeOnline);
            if (res.ok) {
                const data = await res.json();
                window.location.replace(
                    `https://${process.env.REACT_APP_URL?.replace(
                        "www",
                        data.subdomain
                    )}/login/${data.codeOnline}`
                );
            } else {
                setTimeout(() => setIsDone(true), 0);
            }
        } catch (error) {
            enqueueSnackbar(`${i18n.t("errorUnknown")}`, {
                variant: ERROR,
            });
        }
    };

    const handleCustomization = async () => {
        const customizationIsPresent = getCustomizationLocalStorageWithExpiry();

        if (!customizationIsPresent) {
            try {
                const res = await getCustomization();

                if (res.ok) {
                    const customization = (await res.json()) as Customization;

                    const cssVariablesCustomization =
                        formatCssVariablesFromCustomization(
                            customization.customization
                        );

                    setCustomizationLocalStorageWithExpiry(
                        cssVariablesCustomization
                    );
                }
            } catch (error) {
                enqueueSnackbar(`${i18n.t("errorUnknown")}`, {
                    variant: ERROR,
                });
            }
        }
    };

    useMemo(() => {
        setTimeout(() => setIsDone(false), 0);
        if (subdomain) {
            if (currentPath === "/") {
                handleReverseSubdomain();
            }
            handleCustomization();
            setTimeout(() => setIsDone(true), 0);
        } else if (currentPath.includes(`/login/`)) {
            const sessionCode = currentPath.split("/login/")[1];
            retrieveSubdomainToRedirectFromSession(sessionCode);
        } else {
            setTimeout(() => setIsDone(true), 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subdomain, currentPath]);

    return children;
};

export default SubdomainHandler;
