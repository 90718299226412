import styled from "styled-components";

export const Alert = styled.div`
    padding: 1em;
    color: #a94442;
    background-color: #f2dede;
    border: 0px;
    margin-bottom: 0.5rem;
    border-radius: 4px;
    font-size: 14px;
    text-align: left;
    line-height: 1.42857143;
`;
