import styled from "styled-components";

export const BasicButton = styled.button`
    padding: 0.25rem;
    font-size: 0.75rem;
    border: 1px solid #ccc;
    background-color: white;
    color: black;
    :hover {
        cursor: pointer;
        filter: brightness(0.8);
    }
`;
