import { CSS_VARIABLES } from "enums/cssVariables";
import styled from "styled-components";

export const ValidateButton = styled.button`
    width: 100%;
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    cursor: pointer;
    background-color: var(${CSS_VARIABLES.PRIMARY_BUTTON_BACKGROUND_COLOR});
    border: 1px solid transparent;
    height: 40px;
    color: var(${CSS_VARIABLES.PRIMARY_BUTTON_TEXT_COLOR});
    :hover {
        cursor: pointer;
        filter: brightness(0.8);
    }
    :focus-visible {
        outline: solid 3px var(${CSS_VARIABLES.PRIMARY_BUTTON_BACKGROUND_COLOR});
    }
    :disabled {
        opacity: 0.65;
        cursor: not-allowed;
    }
`;
