import { DEVICE_TYPE } from "../../types/deviceType";

import {
    isChrome,
    isChromium,
    isIOS,
    isIOS13,
    isIPod13,
    isAndroid,
    isDesktop,
    isMobile,
    isEdge,
    isOpera,
    isSafari,
    browserName,
    isFirefox,
} from "react-device-detect";

export const getDeviceType = function () {
    if (isIOS13 || isIOS || isIPod13) {
        return DEVICE_TYPE.IOS;
    } else if (isAndroid && (isFirefox || isOpera)) {
        return DEVICE_TYPE.ANDROID_NOT_COMPATIBLE;
    } else if (isChrome || isChromium || isEdge || browserName === "Brave") {
        return DEVICE_TYPE.BROWSER_COMPATIBLE;
    } else if (isDesktop && (isFirefox || isOpera)) {
        return DEVICE_TYPE.DESKTOP_NOT_COMPATIBLE;
    } else {
        return DEVICE_TYPE.DESKTOP;
    }
};

export const isBrowserCanInstall = function () {
    if ((!isDesktop && (isFirefox || isSafari)) || isMobile) {
        return true;
    }
};
