import styled from "styled-components";

export const BaseButton = styled.button`
    border: none;
    padding: ${(props) => props.theme.button.padding};
    margin: 5px;
    width: ${(props) => props.theme.button.width};
    height: ${(props) => props.theme.button.height};
    border-radius: 50%;
    text-align: center;
    font-size: ${(props) => props.theme.button.fontSize};
    :hover {
        cursor: pointer;
        color: #ffffff;
    }
    :focus-visible {
        outline: solid 3px #1e90ff;
    }
`;
