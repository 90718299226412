import {
    CUSTOMIZATION_LOCAL_STORAGE_KEY,
    CUSTOMIZATION_SET_EVENT,
} from "constants/constants";
import { CSS_VARIABLES } from "enums/cssVariables";

export type CustomizationProps = {
    logoAlignment: string;
    titleColor: string;
    titleBackgroundColor: string;
    buttonColor: string;
    buttonBackgroundColor: string;
    logoUrl: string;
    installationButtonDisplay: boolean;
};

const CUSTOMIZATION_PROP_LOGO_URL = "logoUrl";
const CUSTOMIZATION_PROP_LOGO_ALIGNMENT = "logoAlignment";
const CUSTOMIZATION_PROP_BUTTON_BACKGROUND_COLOR = "buttonBackgroundColor";
const CUSTOMIZATION_PROP_BUTTON_COLOR = "buttonColor";
const CUSTOMIZATION_PROP_TITLE_BACKGROUND_COLOR = "titleBackgroundColor";
const CUSTOMIZATION_PROP_TITLE_COLOR = "titleColor";
const CUSTOMIZATION_PROP_INSTALLATION_BUTTON_DISPLAY =
    "installationButtonDisplay";

const customizationPropsToCssVariablesMapping = new Map<string, string>([
    [CUSTOMIZATION_PROP_TITLE_COLOR, CSS_VARIABLES.PRIMARY_TEXT_COLOR],
    [
        CUSTOMIZATION_PROP_TITLE_BACKGROUND_COLOR,
        CSS_VARIABLES.BACKGROUND_FORM_COLOR,
    ],
    [CUSTOMIZATION_PROP_BUTTON_COLOR, CSS_VARIABLES.PRIMARY_BUTTON_TEXT_COLOR],
    [
        CUSTOMIZATION_PROP_BUTTON_BACKGROUND_COLOR,
        CSS_VARIABLES.PRIMARY_BUTTON_BACKGROUND_COLOR,
    ],
    [CUSTOMIZATION_PROP_LOGO_ALIGNMENT, CSS_VARIABLES.LOGO_ALIGNMENT],
    [CUSTOMIZATION_PROP_LOGO_URL, CSS_VARIABLES.LOGO_URL],
    [
        CUSTOMIZATION_PROP_INSTALLATION_BUTTON_DISPLAY,
        CSS_VARIABLES.INSTALLATION_BUTTON_DISPLAY,
    ],
]);

export const formatCssVariablesFromCustomization = (
    props: CustomizationProps
) => {
    if (props.logoUrl) {
        props.logoUrl = `url(${props.logoUrl})`;
    }
    if (props.installationButtonDisplay) {
        props.installationButtonDisplay = "flex" as any;
    }

    return (
        Object.keys(props)
            .filter((field) => props[field as keyof CustomizationProps])
            .map(
                (field) =>
                    `${customizationPropsToCssVariablesMapping.get(field)}: ${
                        props[field as keyof CustomizationProps]
                    }`
            )
            .join("; ") + "; "
    );
};

export const setCustomizationLocalStorageWithExpiry = (value: string) => {
    const now = new Date();
    const item = {
        value: value,
        expiry: now.getTime() + 60 * 1000 * 5, //5mn
    };

    localStorage.setItem(CUSTOMIZATION_LOCAL_STORAGE_KEY, JSON.stringify(item));
    window.dispatchEvent(new Event(CUSTOMIZATION_SET_EVENT));
};

export const getCustomizationLocalStorageWithExpiry = (): string | null => {
    const item = localStorage.getItem(CUSTOMIZATION_LOCAL_STORAGE_KEY);

    if (!item) {
        return null;
    }

    let itemFormatted: { expiry: number; value: string };

    try {
        itemFormatted = JSON.parse(item);
    } catch (error) {
        throw new Error("Impossible to parse customization");
    }

    const now = new Date();

    if (now.getTime() > itemFormatted.expiry) {
        localStorage.removeItem(CUSTOMIZATION_LOCAL_STORAGE_KEY);
        return null;
    }

    return itemFormatted.value;
};
