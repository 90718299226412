import { useEffect, useState } from "react";
import styled from "styled-components";

type Props = {
    text: string;
    isActive: boolean;
    isDisabled?: boolean;
    color?: string;
    onBlur: (arg0: number) => void;
    remainingWeight: number;
    value?: number;
    onKeyUp: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    currentRef: (el: HTMLInputElement) => void;
};

interface StyledButtonProps {
    isActive: boolean;
    disabled?: boolean;
}

const DistributionCaseProposal = ({
    text,
    isActive,
    isDisabled,
    color,
    onBlur,
    remainingWeight,
    value,
    onKeyUp,
    currentRef,
}: Props) => {
    const [localValue, setLocalValue] = useState<number | undefined | "">(
        value
    );

    //After reset the vote, need this ⬇️, for reset each input
    useEffect(() => {
        setLocalValue(value);
    }, [value]);

    const handleChange = (e: { target: HTMLElement & { value: string } }) => {
        // On a mobile with default arrow keys, the focus is not always forced
        // This forces the focus as it is needed to get the onBlur function called
        if (document.activeElement !== e.target) {
            e.target.focus();
        }

        const valueInput = Number(e.target.value);

        if (0 > valueInput || !Number.isInteger(valueInput)) {
            return;
        } else if (valueInput >= remainingWeight + Number(value)) {
            setLocalValue(remainingWeight + Number(value));
        } else {
            setLocalValue(valueInput);
        }
    };

    return (
        <>
            <StyledWrapper
                isActive={isActive}
                color={color}
                disabled={isDisabled}
            >
                <StyledPropositionsText>{text}</StyledPropositionsText>
                <Input
                    type="number"
                    inputMode="numeric"
                    value={localValue?.toString()}
                    onBlur={() => onBlur(localValue as number)}
                    onChange={handleChange}
                    disabled={isDisabled}
                    isActive={isActive}
                    data-testactive={isActive}
                    aria-label={`${text}-vote-distribution`}
                    color={color}
                    onKeyUp={onKeyUp}
                    ref={currentRef}
                />
            </StyledWrapper>
        </>
    );
};

const StyledWrapper = styled.div<StyledButtonProps>`
    background: ${(props) =>
        props.isActive ? (props.color ? props.color : "#60b860") : null};
    color: ${(props) => (props.isActive ? "#ffffff" : null)};
    border: ${(props) =>
        props.color
            ? `1px solid ${props.color}`
            : props.isActive
            ? "1px solid #33a835"
            : "1px solid grey"};
    margin-bottom: 0.5rem;
    min-height: 2.75rem;
    text-align: center;
    width: 100%;

    &[disabled] {
        background: ${(props) =>
            props.isActive
                ? props.color
                    ? props.color
                    : "#60b860"
                : "#e8e8e8"};
        opacity: 0.5;
        cursor: not-allowed;
    }
`;

const Input = styled.input<StyledButtonProps>`
    width: 70%;
    margin: 0.5rem 0;
    padding: 3px;
    background: ${(props) =>
        props.color ? null : props.isActive ? "#60b860" : "#ffffff"};
    color: ${(props) =>
        props.isActive ? (props.color ? props.color : "#ffffff") : null};
    border: 1px solid black;
    text-align: center;
    font-size: 15px;

    &[disabled] {
        color: ${(props) => (props.color ? "#FFFFFF" : "#000000")};
        background: ${(props) =>
            props.color ? "#1c1c1c" : props.isActive ? "#60b860" : "#e8e8e8"};
        opacity: 0.5;
        cursor: not-allowed;
    }

    /* Remove arrow */
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

const StyledPropositionsText = styled.div`
    font-size: 14px;
    padding-top: 0.5rem;
`;

export default DistributionCaseProposal;
