import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Layout from "components/common/Layout";
import { ValidateButton } from "./ValidateButton";
import { DisplayedError } from "services/errors";

type Props = {
    error: DisplayedError;
};

const ErrorPage = (props: Props) => {
    const i18n = useTranslation();

    const renderErrorMessage = () => {
        return (
            <>
                <h1>{i18n.t(`${props.error.header}`)}</h1>
                <h2>{i18n.t(`${props.error.body}`)}</h2>
            </>
        );
    };
    return (
        <Layout>
            <Wrapper>
                {renderErrorMessage()}
                <Link to="/">
                    <ValidateButton>
                        <span>{`${i18n.t("backToHome")}`}</span>
                    </ValidateButton>
                </Link>
            </Wrapper>
        </Layout>
    );
};

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 2rem;

    > h1 {
        font-size: 1.5rem;
        letter-spacing: -1px;
        margin-bottom: 1rem;
        font-weight: 500;
    }
    > h2 {
        font-size: 1rem;
        margin-bottom: 2rem;
        text-align: center;
        font-weight: 500;
    }
`;

export default ErrorPage;
