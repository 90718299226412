import { BrowserRouter, Routes, Route } from "react-router-dom";
import styled from "styled-components";

import HomePage from "./components/HomePage";
import UserLoginPage from "./components/UserLoginPage";
import RedirectSubdomain from "./SubdomainHandler";
import ErrorPage from "./components/common/ErrorPage";
import { NotFoundError } from "services/errors";
import SubdomainHandler from "./SubdomainHandler";
import { useSubdomain } from "components/helper/SubdomainContext";
import Spinner from "components/common/Spinner";
import SessionPageGuard from "components/SessionPageGuard";

const Router = () => {
    const { isDone } = useSubdomain();
    return (
        <BrowserRouter>
            <SubdomainHandler>
                {isDone ? (
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <RedirectSubdomain>
                                    <HomePage />
                                </RedirectSubdomain>
                            }
                        />
                        <Route
                            path="login/:onlineCode"
                            element={<UserLoginPage />}
                        />
                        <Route
                            path="session/:onlineCode"
                            element={<SessionPageGuard />} //Check signature 'Signature Guard'
                        />
                        <Route
                            path="*"
                            element={<ErrorPage error={new NotFoundError()} />}
                        />
                    </Routes>
                ) : (
                    <SpinnerWrapper>
                        <Spinner />
                    </SpinnerWrapper>
                )}
            </SubdomainHandler>
        </BrowserRouter>
    );
};

const SpinnerWrapper = styled.div`
    margin-top: 10rem;
`;

export default Router;
