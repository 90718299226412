import { useState, useEffect } from "react";

export function useHolderVoteMustBeAppliedToProxies(
    weightDistributionIsAllowed: boolean
) {
    const STORAGE_TOGGLE_IS_ACTIVE = "holderVoteMustBeAppliedToProxies";

    const [
        holderVoteMustBeAppliedToProxies,
        setHolderVoteMustBeAppliedToProxies,
    ] = useState<boolean>(
        "true" === localStorage.getItem(STORAGE_TOGGLE_IS_ACTIVE)
    );

    useEffect(() => {
        localStorage.setItem(
            STORAGE_TOGGLE_IS_ACTIVE,
            holderVoteMustBeAppliedToProxies.toString()
        );
    }, [holderVoteMustBeAppliedToProxies]);

    if (weightDistributionIsAllowed) {
        return {
            holderVoteMustBeAppliedToProxies: false,
            setHolderVoteMustBeAppliedToProxies: () => {},
        };
    }

    return {
        holderVoteMustBeAppliedToProxies,
        setHolderVoteMustBeAppliedToProxies,
    };
}
