import React, { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import { VoteStatus } from "./useVoteState";

import { BaseOptionButton } from "../../common/Buttons/BaseOptionButton";

type Props = {
    voteStatus: string;
    voterWeight: number;
    displayName: string;
    children: ReactElement;
};

Modal.setAppElement(document.getElementsByTagName("main")[0]);

interface StyledButtonProps {
    isActive: boolean;
}

const ParticipantButton = ({
    voteStatus,
    voterWeight,
    displayName,
    children,
}: Props) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const i18n = useTranslation();

    useEffect(() => {
        if (VoteStatus.Success === voteStatus) {
            setModalIsOpen(false);
        }
    }, [voteStatus]);

    function toggleModal() {
        setModalIsOpen(!modalIsOpen);
    }

    return (
        <>
            <StyledButton
                data-testid={`voter-${displayName}`}
                onClick={toggleModal}
                isActive={VoteStatus.Success === voteStatus}
            >
                <div>
                    <StyledTextButton>{displayName}</StyledTextButton>
                    {voterWeight > 1 ? (
                        <StyledTextButton>
                            {i18n.t("numberOfVote")} {voterWeight}
                        </StyledTextButton>
                    ) : null}
                </div>
            </StyledButton>
            <Modal
                closeTimeoutMS={200}
                isOpen={modalIsOpen}
                onRequestClose={toggleModal}
                contentLabel="Vote"
                style={customStylesModal}
                ariaHideApp={false}
            >
                <ModalHeader>
                    <div>
                        {displayName} <br />
                    </div>

                    <Cross data-testid="modal-cross" onClick={toggleModal}>
                        <i className="fa fa-times" aria-hidden="true"></i>
                    </Cross>
                </ModalHeader>
                <ModalBody>{children}</ModalBody>
            </Modal>
        </>
    );
};

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 20px 15px 20px 15px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    border-bottom: 1px solid #e5e5e5;
`;

const ModalBody = styled.div`
    padding: 20px 15px 20px 15px;
`;

const Cross = styled.div`
    color: grey;
    :hover {
        color: red;
        cursor: pointer;
    }
`;

const StyledTextButton = styled.p`
    margin: 4px 0 0 0;
`;

const StyledButton = styled(BaseOptionButton)<StyledButtonProps>`
    background: ${(props) => (props.isActive ? "#60b860" : null)};
    color: ${(props) => (props.isActive ? "#ffffff" : null)};
    border: ${(props) => (props.isActive ? "1px solid #60b860" : null)};
    padding: 0.5rem 1.5rem;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    &[disabled] {
        background: ${(props) => (props.isActive ? "#60b860" : "#e8e8e8")};
        opacity: 0.7;
        cursor: not-allowed;
    }
`;

const customStylesModal = {
    overlay: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(142, 142, 142, 0.6)",
        overflowY: "scroll" as const,
    },
    content: {
        position: "initial" as const,
        margin: "10rem auto auto",
        padding: 0,
        border: "1px solid rgba(0,0,0,0.2)",
        maxHeight: "fit-content",
        maxWidth: "620px",
        borderRadius: 0,
        boxShadow: "0 5px 15px rgb(0 0 0 / 50%)",
    },
};

export default ParticipantButton;
