import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { Language } from "../Language";
import translationEN from "./en.json";
import translationFR from "./fr.json";
import translationDE from "./de.json";
import translationES from "./es.json";

const resources = {
    en: {
        translation: translationEN,
    },
    fr: {
        translation: translationFR,
    },
    de: {
        translation: translationDE,
    },
    es: {
        translation: translationES,
    },
};

i18n.use(initReactI18next).use(LanguageDetector).init({
    fallbackLng: Language.FR,
    resources,
});

export default i18n;
