import React from "react";
import styled from "styled-components";

import { VoteStatus } from "types/voteEnum";

import BasicProposalButton from "components/vote/Quizzbox/common/Buttons/BasicProposalButton";

type Props = {
    options: Array<{
        id: string;
        text: string;
    }>;
    answers: Array<string | undefined>;
    index: number;
    setVoteStatus: React.Dispatch<React.SetStateAction<VoteStatus>>;
    setAnswers: React.Dispatch<React.SetStateAction<Array<string | undefined>>>;
};

const OptionsWrapper = ({
    options,
    answers,
    index,
    setAnswers,
    setVoteStatus,
}: Props) => {
    const renderOptions = () => {
        return options.map((option, item) => {
            const isActive = answers[index - 1] === option.id;

            const onClickProposal = () => {
                const newAnswers = [...answers];
                if (isActive) {
                    newAnswers[index - 1] = undefined;
                    setAnswers(newAnswers);
                    setVoteStatus(VoteStatus.Idle);
                    return;
                }

                newAnswers[index - 1] = option.id;
                setAnswers(newAnswers);

                return;
            };
            return (
                <BasicProposalButton
                    text={option.text}
                    isActive={isActive}
                    onClick={onClickProposal}
                    key={item}
                />
            );
        });
    };
    return <Wrapper>{renderOptions()}</Wrapper>;
};

const Wrapper = styled.div`
    margin-top: 15px;
`;

export default OptionsWrapper;
