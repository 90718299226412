import styled from "styled-components";

type Props = {
    title: string;
};

const TitleHeader = ({ title }: Props) => {
    return <WrapperHeader>{title}</WrapperHeader>;
};

const WrapperHeader = styled.div`
    font-size: 1.2rem;
    font-weight: bold;
    background-color: #f5f5f5;
    font-size: 14px;
    padding: 0.75rem 0.5rem;
    margin-bottom: 1rem;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
`;

export default TitleHeader;
