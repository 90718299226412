import { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { VoteStatus } from "types/voteEnum";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import groupAndSortElectionProposals from "../services/groupAndSortElectionProposals";

import BasicCaseProposal from "components/vote/Votebox/common/Buttons/BasicCaseProposal";
import ActionSection from "components/vote/Votebox/common/ActionSection";

type Proposal = {
    disableOtherPropositionsIfSelected: boolean;
    id: string;
    text: string;
    isVisibleByDefault: boolean;
};

type Props = {
    propositions: Array<Proposal>;
    answers: Array<{
        id: string;
        weight: number;
        disableOtherPropositionsIfSelected: boolean;
    }>;
    isSecret: boolean;
    voterWeight: number;
    voteStatus: string;
    maxNumberOfAnswers: number;
    selectProposal: (propositionId: string) => void;
    unselectProposal: (propositionId: string) => void;
    validateVote: () => void;
    resetVote: () => void;
};

const VoteBasic = ({
    propositions,
    answers,
    isSecret,
    voterWeight,
    voteStatus,
    maxNumberOfAnswers,
    selectProposal,
    unselectProposal,
    validateVote,
    resetVote,
}: Props) => {
    const [shouldShowHiddenProposals, setShouldShowHiddenProposals] =
        useState(false);
    const [shouldSortActiveAnswers, setShouldSortActiveAnswers] =
        useState(false);

    const i18n = useTranslation();

    const numberOfActiveAnswers = answers.filter(
        (answer) => answer.weight
    ).length;

    const renderSortButton = () => {
        return (
            <>
                {maxNumberOfAnswers > 1 ? (
                    <StyledSortSelection
                        onClick={() =>
                            setShouldSortActiveAnswers(!shouldSortActiveAnswers)
                        }
                    >
                        <ArrowDropUp
                            style={{ fontSize: "20px", marginRight: "2px" }}
                        />
                        {i18n.t("sortSelection")}
                        {` (${
                            answers.filter((answer) => answer.weight > 0).length
                        }/${maxNumberOfAnswers})`}
                    </StyledSortSelection>
                ) : null}
            </>
        );
    };

    const renderToggleHiddenProposalsButton = () => {
        /* If one proposal is hidden, display the toggle  */
        return propositions.some(
            (proposition) => !proposition.isVisibleByDefault
        ) ? (
            <WrapperToggle
                onClick={() =>
                    setShouldShowHiddenProposals(!shouldShowHiddenProposals)
                }
            >
                <MoreHorizIcon
                    style={{ fontSize: "20px", marginRight: "2px" }}
                />
                <StyledToggleText>
                    {shouldShowHiddenProposals
                        ? i18n.t(`seeLess.${process.env.REACT_APP_THEME}`)
                        : i18n.t(`seeMore.${process.env.REACT_APP_THEME}`)}
                </StyledToggleText>
            </WrapperToggle>
        ) : null;
    };

    const { visibleProposals, hiddenProposals } = groupAndSortElectionProposals(
        propositions.map((prop) => {
            const isActive =
                VoteStatus.Success === voteStatus && isSecret
                    ? false
                    : answers.some(
                          (answer) => answer.id === prop.id && answer.weight > 0
                      );

            return {
                ...prop,
                isActive,
            };
        }),
        { sortingIsEnabled: shouldSortActiveAnswers }
    );

    const renderProposals = (
        proposals: Array<Proposal & { isActive: boolean }>
    ) => {
        const activeUniqueAnswerId = answers
            .filter((answer) => answer.disableOtherPropositionsIfSelected)
            .find((answer) => answer.weight > 0);

        const isUniqueAnswerConstraintActive = Boolean(activeUniqueAnswerId);

        return proposals.map((proposition, item) => {
            const isDisabled = () => {
                if (VoteStatus.Success === voteStatus) {
                    return true;
                }

                if (isUniqueAnswerConstraintActive) {
                    return activeUniqueAnswerId?.id !== proposition.id;
                }

                if (proposition.isActive) {
                    return false;
                }

                return numberOfActiveAnswers === maxNumberOfAnswers;
            };

            const handleProposalClick = () => {
                if (proposition.isActive) {
                    unselectProposal(proposition.id);
                    return;
                }

                selectProposal(proposition.id);
            };

            return (
                <BasicCaseProposal
                    text={proposition.text}
                    isActive={proposition.isActive}
                    isDisabled={isDisabled()}
                    onClick={handleProposalClick}
                    key={item}
                />
            );
        });
    };

    return (
        <Wrapper>
            {voterWeight > 1 ? (
                <StyledNumberOfVoices>
                    {i18n.t("numberOfVote")} {voterWeight}
                </StyledNumberOfVoices>
            ) : null}
            {renderSortButton()}
            {renderProposals(visibleProposals)}
            {renderToggleHiddenProposalsButton()}
            {shouldShowHiddenProposals
                ? renderProposals(hiddenProposals)
                : null}
            <ActionSection
                validateVote={validateVote}
                resetVote={resetVote}
                voteStatus={voteStatus as VoteStatus}
            />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    margin-top: 15px;
`;

const StyledNumberOfVoices = styled.div`
    color: #626262;
    padding-bottom: 0.5rem;
    font-size: 14px;
`;

const StyledSortSelection = styled.div`
    display: flex;
    align-items: center;
    font-size: 11px;
    font-weight: 600;
    padding-bottom: 1rem;
    justify-content: end;
    cursor: pointer;
`;

const WrapperToggle = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 0.5em;
    font-size: 14px;
    cursor: pointer;
`;

const StyledToggleText = styled.span`
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-left: 5px;
    font-weight: bold;
`;

export default VoteBasic;
