export const orange = {
    backgroundColor: "#f47e21",
    border: "#b45204",
    screen: {
        backgroundColor: "#e8e8e8",
        height: "70px",
    },
    button: {
        width: "50px",
        height: "50px",
        color: "#FFFFFF",
        fontSize: "20px",
        fontWeight: "300",
        hoverBackgroundColor: "#b45204",
        boxShadow: "#7b3c0a 0 0 6px",
    },
    validateButton: {
        backgroundColor: "#008000",
        fontColor: "#FFFFFF",
    },
    resetButton: {
        fontColor: "#FFFFFF",
    },
};

export const white = {
    backgroundColor: "#FFFFFF",
    border: "#607D91",
    screen: {
        backgroundColor: "#e2e2e2",
        height: "60px",
    },
    button: {
        width: "50px",
        height: "50px",
        padding: "8px",
        color: "#607d91",
        fontSize: "22px",
        fontWeight: "700",
        hoverBackgroundColor: "#607d91",
        boxShadow: "#0c0c0cd9 0 0 3px",
    },
    validateButton: {
        backgroundColor: "#FFFFFF",
        fontColor: "#008000",
        hoverBackgroundColor: "#607d91",
    },
    resetButton: {
        fontColor: "#FF0000",
    },
};
