interface Proposal {
    isActive: boolean;
    text: string;
    isVisibleByDefault: boolean;
}

function groupAndSortElectionProposals<T extends Proposal>(
    proposals: Array<T>,
    { sortingIsEnabled } = { sortingIsEnabled: false }
): { visibleProposals: Array<T>; hiddenProposals: Array<T> } {
    const visibleProposals: Array<T> = [];
    const hiddenProposals: Array<T> = [];

    proposals.forEach((proposal) => {
        if (
            proposal.isVisibleByDefault ||
            (proposal.isActive && sortingIsEnabled)
        ) {
            visibleProposals.push(proposal);
        } else {
            hiddenProposals.push(proposal);
        }
    });

    if (sortingIsEnabled) {
        visibleProposals.sort(function (propA, propB) {
            if (propA.isActive && propB.isActive) return 0;
            if (propA.isActive && !propB.isActive) return -1;

            return 1;
        });
    }

    return {
        visibleProposals,
        hiddenProposals,
    };
}

export default groupAndSortElectionProposals;
