import { useEffect, useRef, useState } from "react";
import SignaturePad from "signature_pad";
import styled from "styled-components";

import { CSS_VARIABLES } from "enums/cssVariables";
import { useTranslation } from "react-i18next";
import { performSignature } from "components/helper/callApi";
import { useUser } from "components/helper/userContext";
import { useHandleErrors } from "./common/handleErrors";
import ErrorPage from "../common/ErrorPage";
import { DisplayedError, NotFoundError, UnknownError } from "services/errors";
import Layout from "../common/Layout";
import LogoutWrapper from "components/common/LogoutWrapper";
import SignatureLogoutButton from "./common/SignatureLogoutButton";

const Signature = () => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const signaturePadRef = useRef<SignaturePad>();
    const [isSignaturePadEmpty, setIsSignaturePadEmpty] = useState(true);

    const { user, setUser } = useUser();
    const [error, setError] = useState<DisplayedError | null>(null);

    const { handleLoginError } = useHandleErrors();

    const i18n = useTranslation();

    const drawText = (context: CanvasRenderingContext2D) => {
        context.font = "italic 20px 'Helvetica Neue', Arial, sans-serif";
        context.fillStyle = "lightgray";
        context.fillText(i18n.t("signature.signHere"), 150, 150);
        context.fillText(user?.displayName as string, 150, 180);
    };

    useEffect(() => {
        const canvas = canvasRef.current;

        if (canvas) {
            canvas.width = 400;
            canvas.height = 300;

            signaturePadRef.current = new SignaturePad(canvas, {
                backgroundColor: "#ffffff",
                minWidth: 1,
                maxWidth: 2,
            });

            const context = canvas.getContext("2d");
            if (context) {
                drawText(context);
            }

            if (signaturePadRef.current) {
                signaturePadRef.current.addEventListener("endStroke", () => {
                    setIsSignaturePadEmpty(
                        signaturePadRef.current?.isEmpty() ?? true
                    );
                });
            }
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSignature = async (signature: string | null) => {
        try {
            const sendSignature = await performSignature({
                signature: signature as string,
            });

            if (sendSignature.ok) {
                const refreshedUser = await sendSignature.json();
                setUser(refreshedUser);
            } else {
                handleLoginError(sendSignature.status);
            }
        } catch (error) {
            setError(error as NotFoundError | UnknownError);
        }
    };

    const handleClear = () => {
        if (isSignaturePadEmpty) {
            return;
        }

        signaturePadRef.current?.clear();
        setIsSignaturePadEmpty(true);

        const canvas = canvasRef.current;
        const context = canvas?.getContext("2d");
        if (context) {
            drawText(context);
        }
    };

    const handleValidate = async () => {
        if (!isSignaturePadEmpty) {
            setIsSignaturePadEmpty(false);
            const data = signaturePadRef.current?.toDataURL() ?? null;
            await handleSignature(data);
        }
    };

    if (error) {
        return <ErrorPage error={error} />;
    }

    return (
        <>
            <LogoutWrapper>
                <SignatureLogoutButton />
            </LogoutWrapper>
            <Layout>
                <>
                    <Wrapper>
                        <StyledCanvas ref={canvasRef} />
                        <ButtonWrapper>
                            <StyledOutlineButton
                                onClick={handleClear}
                            >{`${i18n.t(
                                "signature.clear"
                            )}`}</StyledOutlineButton>
                            <StyledButton
                                onClick={handleValidate}
                                disabled={isSignaturePadEmpty}
                            >
                                {`${i18n.t("validate")}`}
                            </StyledButton>
                        </ButtonWrapper>
                    </Wrapper>
                </>
            </Layout>
        </>
    );
};

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    > p {
        margin: auto;
        padding: 2rem 0 1rem 0;
        font-size: 0.75rem;
        line-height: 22px;
        text-align: left;
        width: 620px;
        > a {
            text-decoration: underline;
            color: black;
        }
        @media (max-width: 768px) {
            width: 320px;
        }
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    width: 400px;
    justify-content: end;
`;

const StyledCanvas = styled.canvas`
    border: 1px solid gray;
    cursor: crosshair;
`;

const StyledOutlineButton = styled.button.attrs({ type: "button" })`
    padding: 6px 12px;
    margin: 0.5rem 0.5rem;
    font-size: 14px;
    cursor: pointer;
    background-color: #fafafa;
    border: 1px solid var(${CSS_VARIABLES.PRIMARY_BUTTON_BACKGROUND_COLOR});
    color: var(${CSS_VARIABLES.PRIMARY_BUTTON_BACKGROUND_COLOR});
    :hover {
        cursor: pointer;
        filter: brightness(0.8);
    }
    :focus-visible {
        outline: solid 2px var(${CSS_VARIABLES.PRIMARY_BUTTON_BACKGROUND_COLOR});
    }
    :disabled {
        cursor: not-allowed;
        filter: brightness(0.8);
    }
`;

const StyledButton = styled(StyledOutlineButton)`
    background-color: var(${CSS_VARIABLES.PRIMARY_BUTTON_BACKGROUND_COLOR});
    border: 1px solid var(${CSS_VARIABLES.PRIMARY_BUTTON_BACKGROUND_COLOR});
    color: #fff;
    margin-right: 0;
    :focus-visible {
        outline: solid 2px var(${CSS_VARIABLES.PRIMARY_BUTTON_BACKGROUND_COLOR});
    }
`;

export default Signature;
