import styled from "styled-components";

import { VoteStatus } from "../Deliberation/useVoteState";

import BasicCaseProposal from "./Buttons/BasicCaseProposal";

type Props = {
    propositions: Array<{
        disableOtherPropositionsIfSelected: boolean;
        id: string;
        shortText: string;
        isVisibleByDefault: boolean;
    }>;
    answers: Array<{
        id: string;
        weight: number;
        disableOtherPropositionsIfSelected: boolean;
    }>;
    isSecret: boolean;
    voteStatus: string;
    replaceProposal: (propositionId: string) => void;
    proposalsOrientation: string;
    voterName: string;
};

interface StyledButtonProps {
    proposalsOrientation: string;
}

const VoteBasic = ({
    propositions,
    answers,
    isSecret,
    voteStatus,
    replaceProposal,
    proposalsOrientation,
    voterName,
}: Props) => {
    const renderProposals = () => {
        return propositions.map((proposition, item) => {
            const isActive =
                VoteStatus.Success === voteStatus && isSecret
                    ? false
                    : answers.some(
                          (answer) =>
                              answer.id === proposition.id && answer.weight > 0
                      );

            const isDisabled = () => {
                if (VoteStatus.Success === voteStatus) {
                    return true;
                }

                if (isActive) {
                    return false;
                }

                return false;
            };

            const handleProposalClick = () => {
                replaceProposal(proposition.id);
            };

            const renderColorBtn = () => {
                if ("1" === proposition.id) {
                    return "#60B860";
                } else if ("2" === proposition.id) {
                    return "#E10032";
                } else {
                    return "#737373";
                }
            };

            return (
                <BasicCaseProposal
                    text={proposition.shortText}
                    isActive={isActive}
                    isDisabled={isDisabled()}
                    onClick={handleProposalClick}
                    key={item}
                    color={renderColorBtn()}
                />
            );
        });
    };

    return (
        <Wrapper>
            {/* In case of proxies, the position of proposals is horizontal */}
            <StyledProposals
                proposalsOrientation={proposalsOrientation}
                data-testid={`voter-${voterName}`}
            >
                {renderProposals()}
            </StyledProposals>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    margin-top: 7px;
`;

const StyledProposals = styled.div<StyledButtonProps>`
    display: ${(props) =>
        "horizontal" === props.proposalsOrientation ? "flex" : null};
    gap: ${(props) =>
        "horizontal" === props.proposalsOrientation ? "1rem" : null};
`;

export default VoteBasic;
