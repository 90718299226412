import { useSession } from "components/helper/sessionContext";
import { useUser } from "components/helper/userContext";
import ZoomMtgEmbedded from "@zoom/meetingsdk/embedded";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const MeetingZoom = () => {
    const { session } = useSession();
    const { user } = useUser();
    const { i18n } = useTranslation();

    const initZoom = () => {
        const client = ZoomMtgEmbedded.createClient();

        const meetingSDKElement = document.getElementById(
            "meetingSDKElement"
        ) as HTMLElement;

        const langBase = session?.lang || i18n.language;
        const formattedLanguage = `${langBase.toLowerCase()}-${langBase.toUpperCase()}`;

        client
            .init({
                zoomAppRoot: meetingSDKElement,
                language: formattedLanguage as any,
                patchJsMedia: true,
            })
            .then(() => {
                const sdkKey = process.env.REACT_APP_ZOOM_SDK_KEY as string;
                const meetingNumber = session?.streamingLogin as string;
                const password = session?.streamingPassword as string;
                const userName = user?.displayName as string;
                const userEmail = user?.email || ("" as string);
                const signature = session?.zoomSignature as string;

                client
                    .join({
                        signature,
                        sdkKey,
                        meetingNumber,
                        password,
                        userName: userName,
                        userEmail: userEmail,
                    })
                    .then(() => {
                        console.log("joined successfully");
                    })
                    .catch((error) => {
                        console.log("Zoom join failed with error: ", error);
                    });
            })
            .catch((error) => {
                console.log("Zoom init failed with error: ", error);
            });
    };

    useEffect(() => {
        initZoom();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div id="meetingSDKElement"></div>;
};

export default MeetingZoom;
