import {
    VoteStateGroupedDeliberation,
    Deliberation,
} from "../GroupedDeliberation/useVoteState";

type Action = {
    type: "updateWeightVote";
    idDeliberation: number;
    id: string;
    weight: number;
    voterId: string;
};

export const getDeliberation = (
    vote: VoteStateGroupedDeliberation,
    action: Action
) => {
    return vote.deliberations.find(
        (deliberation) => action.idDeliberation === deliberation.idDeliberation
    ) as Deliberation;
};

// Calculate the cumulative weight for each deliberation's answers
export const getAndCalculateCumulativeWeightPerDeliberation = (
    newDeliberations: Array<Deliberation>
) => {
    return newDeliberations
        .map((deliberation) => deliberation.answers)
        .map((answers) =>
            answers.reduce((cumul, answer) => cumul + answer.weight, 0)
        );
};
