import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { CSS_VARIABLES } from "enums/cssVariables";

type Props = {
    checked: boolean;
    onChange: (checked: boolean) => void;
};

const ToggleSwitch = ({ checked, onChange }: Props) => {
    const i18n = useTranslation();

    const handleEnterAccessibility = (
        e: React.KeyboardEvent<HTMLLabelElement>
    ) => {
        if ("Enter" === e.key) {
            onChange(!checked);
        }
    };

    return (
        <ToggleWrapper>
            <div>{i18n.t("sameVoteForAllProxies")}</div>
            <CheckBoxWrapper>
                <CheckBox
                    id="checkbox"
                    type="checkbox"
                    checked={checked}
                    onChange={() => onChange(!checked)}
                    tabIndex={-1}
                />
                <CheckBoxLabel
                    htmlFor="checkbox"
                    tabIndex={0}
                    onKeyDown={handleEnterAccessibility}
                />
            </CheckBoxWrapper>
        </ToggleWrapper>
    );
};

const ToggleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0.75rem;
    margin: 1rem -0.7rem 1rem -0.7rem;
    align-items: center;
    background-color: #f5f5f5;
    font-size: 15px;
`;

const CheckBoxWrapper = styled.div`
    position: relative;
`;

const CheckBoxLabel = styled.label`
    position: absolute;
    top: 4px;
    left: 0;
    width: 42px;
    height: 26px;
    border-radius: 15px;
    background: #bebebe;
    cursor: pointer;
    :focus-visible {
        outline: solid 3px var(${CSS_VARIABLES.PRIMARY_BUTTON_BACKGROUND_COLOR});
    }
    &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 18px;
        height: 18px;
        margin: 3px;
        background: #ffffff;
        box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
        transition: 0.2s;
    }
`;

const CheckBox = styled.input`
    opacity: 0;
    z-index: 1;
    width: 42px;
    height: 25px;
    &:checked + ${CheckBoxLabel} {
        background: #60b860;
        &::after {
            content: "";
            display: block;
            border-radius: 50%;
            width: 18px;
            height: 18px;
            margin-left: 21px;
            transition: 0.2s;
        }
    }
`;

export default ToggleSwitch;
