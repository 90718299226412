import styled from "styled-components";
import { useForm } from "react-hook-form";

import { Input } from "components/common/Input";
import { ValidateButton } from "components/common/ValidateButton";

type Props = {
    fields: Array<{
        name: string;
        placeholder: string;
        ariaLabel: string;
    }>;
    onSubmit: (data: any) => Promise<void>;
    formMessage: string;
    formButtonText: string;
};

const Form = ({ fields, onSubmit, formMessage, formButtonText }: Props) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    return (
        <FormWrapper>
            {fields[0].name === "idReference" ? (
                <StyledMessage>{formMessage}</StyledMessage>
            ) : null}
            <form onSubmit={handleSubmit(onSubmit)}>
                {fields.map((field, index) => (
                    <StyledInput
                        {...register(field.name, { required: true })}
                        type={field.name === "email" ? "email" : "text"}
                        error={Boolean(errors[field.name])}
                        placeholder={field.placeholder}
                        aria-invalid={errors[field.name] ? "true" : "false"}
                        key={index}
                    />
                ))}

                <StyledButton type="submit">{formButtonText}</StyledButton>
            </form>
        </FormWrapper>
    );
};

const StyledInput = styled(Input)<{ error: boolean }>`
    &:focus {
        border-color: ${(props) => (props.error ? " #dc3545" : null)};
        box-shadow: ${(props) =>
            props.error ? " 0 0 0 0.2rem rgb(220 53 69 / 25%);" : null};
    }
    border-color: ${(props) => (props.error ? " #dc3545" : null)};
    box-shadow: ${(props) =>
        props.error ? " 0 0 0 0.2rem rgb(220 53 69 / 25%);" : null};
`;

const FormWrapper = styled.div`
    padding: 1.5rem;
`;

const StyledMessage = styled.p`
    margin: 0;
    padding-bottom: 1em;
    font-size: 14px;
    line-height: 1.5rem;
`;

const StyledButton = styled(ValidateButton)``;

export default Form;
