import "./i18n/i18n";
import { SnackbarProvider, closeSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";

import { UserContextProvider } from "components/helper/userContext";
import { SessionContextProvider } from "components/helper/sessionContext";

import Router from "./Router";
import GlobalStylesWrapper from "components/GlobalStylesWrapper";
import { SubdomainProvider } from "components/helper/SubdomainContext";

function App() {
    return (
        <>
            <SessionContextProvider>
                <SnackbarProvider
                    maxSnack={1}
                    autoHideDuration={3000}
                    transitionDuration={{ enter: 120, exit: 150 }}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    style={{
                        backgroundColor: "rgba(211,71,66,0.90)",
                        color: "#000",
                    }}
                    hideIconVariant={true}
                    action={(snackbarId) => (
                        <div onClick={() => closeSnackbar(snackbarId)}>
                            <CloseIcon
                                style={{
                                    position: "absolute",
                                    top: "3px",
                                    right: "3px",
                                    fontSize: "17px",
                                    color: "#000",
                                    cursor: "pointer",
                                }}
                            />
                        </div>
                    )}
                >
                    <UserContextProvider>
                        <GlobalStylesWrapper />
                        <SubdomainProvider>
                            <Router />
                        </SubdomainProvider>
                    </UserContextProvider>
                </SnackbarProvider>
            </SessionContextProvider>
        </>
    );
}

export default App;
