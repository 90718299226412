export const getSubdomain = () => {
    const hostname = window.location.hostname;
    const baseUrlDomain = (process.env.REACT_APP_URL as string).split(".")[0];
    const hostSubdomain = hostname.split(".")[0];
    return hostSubdomain !== baseUrlDomain ? hostSubdomain : undefined;
};

export const getUrl = (): string => {
    const subdomain = getSubdomain();
    if (subdomain) {
        return (process.env.REACT_APP_ONLINE_API_BASE_URL as string).replace(
            "https://",
            `https://${subdomain}.`
        );
    } else {
        return process.env.REACT_APP_ONLINE_API_BASE_URL as string;
    }
};
