import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";

import { LS_AUTH_TOKEN_KEY } from "constants/constants";
import { useSession } from "components/helper/sessionContext";
import { logOut } from "components/helper/callApi";
import { useUser } from "components/helper/userContext";

import { ERROR } from "types/snackBar.constant";
import React from "react";

type Props = {
    children: JSX.Element;
};

const LogoutWrapper = ({ children }: Props) => {
    const { setUser } = useUser();
    const { session } = useSession();
    const navigate = useNavigate();
    const i18n = useTranslation();

    const handleLogOut = () => {
        logOut()
            .then((res: any) => {
                if (res.ok) {
                    setUser(null);
                    localStorage.removeItem(LS_AUTH_TOKEN_KEY);
                    session &&
                        navigate(`/login/${session.codeOnline}`, {
                            replace: true,
                        });
                } else {
                    enqueueSnackbar(`${i18n.t("errorUnknown")}`, {
                        variant: ERROR,
                    });
                }
            })
            .catch((e: any) => {
                enqueueSnackbar(`${i18n.t("errorUnknown")}`, {
                    variant: ERROR,
                });
            });
    };

    const element = React.Children.only(children);
    return React.cloneElement(element, { handler: handleLogOut });
};

export default LogoutWrapper;
