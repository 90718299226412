import { useReducer } from "react";
import { QuestionType } from "types/dataVoteType";
import {
    getAndCalculateCumulativeWeightPerDeliberation,
    getDeliberation,
} from "../services/reducerHelper";

export type Answer = {
    id: string;
    weight: number;
    disableOtherPropositionsIfSelected: boolean;
};

export type Deliberation = {
    idDeliberation: number;
    answers: Array<Answer>;
};

export type VoteStateGroupedDeliberation = {
    voterId: string;
    voteStatus: VoteStatus;
    deliberations: Array<Deliberation>;
};

export enum VoteStatus {
    PendingValidation = "pendingValidation",
    Idle = "idle",
    Loading = "loading",
    Success = "success",
    Error = "error",
}

type State = Array<VoteStateGroupedDeliberation>;

export type Person = {
    displayName: string;
    numTelecoEncrypted: string;
    weight: number;
};

type Proposal = {
    id: string;
    disableOtherPropositionsIfSelected: boolean;
};

export type VoteStateAction =
    | {
          type: "updateWeightVote";
          idDeliberation: number;
          id: string;
          weight: number;
          voterId: string;
      }
    | { type: "sendAnswersRequest"; voterId: string }
    | { type: "sendAnswersSuccess"; voterId: string }
    | { type: "sendAnswersError"; voterId: string }
    | { type: "resetVote"; voterId: string };

function useVoteState(
    questions: Array<QuestionType>,
    proxies: Array<Person>,
    user: Person
) {
    function reducer(state: State, action: VoteStateAction): State {
        switch (action.type) {
            case "updateWeightVote": {
                return [
                    ...state.map((vote) => {
                        if (vote.voterId !== action.voterId) {
                            return vote;
                        }

                        // Find the voter and deliberation that match the action data
                        const voter = [user, ...proxies].find(
                            (person) =>
                                person.numTelecoEncrypted === vote.voterId
                        ) as Person;

                        const deliberationMatch = getDeliberation(vote, action);

                        // Check if the current vote should disable other propositions
                        const currentVoteShouldDisabledOtherPropositions =
                            deliberationMatch?.answers.find(
                                (answer) => answer.id === action.id
                            ) &&
                            /* Useful only in case of a vote with distribution
                               We don't want to disable other propositions if the weight is not the total weight */
                            action.weight === voter.weight;

                        // Update the answers in the deliberation

                        const newAnswers = deliberationMatch.answers.map(
                            (answer) => {
                                if (answer.id === action.id) {
                                    return { ...answer, weight: action.weight };
                                } else if (
                                    currentVoteShouldDisabledOtherPropositions
                                ) {
                                    return { ...answer, weight: 0 };
                                } else {
                                    return answer;
                                }
                            }
                        );

                        // Retrieve and update the answers of the current deliberation from the action
                        const newGroupedDeliberation = vote.deliberations.map(
                            (deliberation) => {
                                if (
                                    deliberation.idDeliberation ===
                                    action.idDeliberation
                                ) {
                                    return {
                                        ...deliberation,
                                        answers: newAnswers,
                                    };
                                } else {
                                    return deliberation;
                                }
                            }
                        );

                        const cumulativeWeightPerDeliberation =
                            getAndCalculateCumulativeWeightPerDeliberation(
                                newGroupedDeliberation
                            );

                        // Update the vote status based on the cumulative weights
                        const newVoteStatus =
                            cumulativeWeightPerDeliberation.every(
                                (element) => element === voter.weight
                            )
                                ? VoteStatus.PendingValidation
                                : VoteStatus.Idle;

                        // Return the updated vote object
                        return {
                            ...vote,
                            voteStatus: newVoteStatus,
                            deliberations: newGroupedDeliberation,
                        };
                    }),
                ];
            }
            case "sendAnswersRequest": {
                return [
                    ...state.map((vote) => {
                        if (vote.voterId !== action.voterId) {
                            return vote;
                        }

                        return {
                            ...vote,
                            voteStatus: VoteStatus.Loading,
                        };
                    }),
                ];
            }
            case "sendAnswersSuccess": {
                return [
                    ...state.map((vote) => {
                        if (vote.voterId !== action.voterId) {
                            return vote;
                        }

                        return {
                            ...vote,
                            voteStatus: VoteStatus.Success,
                        };
                    }),
                ];
            }
            case "sendAnswersError": {
                return [
                    ...state.map((vote) => {
                        if (vote.voterId !== action.voterId) {
                            return vote;
                        }

                        return {
                            ...vote,
                            voteStatus: VoteStatus.Error,
                        };
                    }),
                ];
            }
            case "resetVote": {
                return [
                    ...state.map((vote) => {
                        if (vote.voterId !== action.voterId) {
                            return vote;
                        }

                        const newDeliberation = vote.deliberations.map(
                            (deliberation) => {
                                return {
                                    ...deliberation,
                                    answers: createInitialAnswers(
                                        questions[
                                            deliberation.idDeliberation - 1
                                        ].propositions
                                    ),
                                };
                            }
                        );

                        return {
                            ...vote,
                            deliberations: newDeliberation,
                            voteStatus: VoteStatus.Idle,
                        };
                    }),
                ];
            }
        }
    }

    const deliberations = questions.map((question) => {
        return {
            idDeliberation: question.id,
            answers: createInitialAnswers(question.propositions),
        };
    });

    const initialState: Array<VoteStateGroupedDeliberation> = [
        user,
        ...proxies,
    ].map((person) => ({
        voterId: person.numTelecoEncrypted,
        voteStatus: VoteStatus.Idle,
        deliberations: deliberations,
    }));

    return useReducer(reducer, initialState);
}

function createInitialAnswers(proposals: Array<Proposal>): Array<{
    id: string;
    weight: number;
    disableOtherPropositionsIfSelected: boolean;
}> {
    return proposals.map((proposal) => ({
        id: proposal.id,
        weight: 0,
        disableOtherPropositionsIfSelected:
            proposal.disableOtherPropositionsIfSelected,
    }));
}

export default useVoteState;
