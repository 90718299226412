import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { DEVICE_TYPE } from "types/deviceType";
import { getDeviceType } from "components/helper/deviceService";

import { Dialog } from "@mui/material";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import CloseIcon from "@mui/icons-material/Close";
import IosShareIcon from "@mui/icons-material/IosShare";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import AddToHomeScreenOutlinedIcon from "@mui/icons-material/AddToHomeScreenOutlined";

type Props = {
    trigger: number;
};

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const OnBoarding = ({ trigger }: Props) => {
    const [isOpen, setOpen] = useState<boolean>(false);

    const isIOSDevice = DEVICE_TYPE.IOS === getDeviceType();
    const BrowserDesktopIsNotCompatible =
        DEVICE_TYPE.DESKTOP_NOT_COMPATIBLE === getDeviceType();

    const i18n = useTranslation();

    useEffect(() => {
        if (trigger) {
            setOpen(true);
        }
    }, [trigger]);

    const renderOnBoarding = () => {
        if (isIOSDevice) {
            return (
                <>
                    <div>
                        <p>{`${i18n.t("onboarding.ios.step1")}`}</p>
                        <WrapperIcon>
                            <IosShareIcon />
                        </WrapperIcon>
                    </div>
                    <div>
                        <p>{`${i18n.t("onboarding.ios.step2")}`}</p>
                        <WrapperIcon>
                            <AddBoxOutlinedIcon />
                        </WrapperIcon>
                    </div>
                    <div>
                        <p>{`${i18n.t("onboarding.step3")}`}</p>
                    </div>
                </>
            );
        } else if (BrowserDesktopIsNotCompatible) {
            return (
                <StyledNoAllowInstall>
                    <p>{`${i18n.t("onboarding.notCompatible.step1")}`}</p>
                    <p> {`${i18n.t("onboarding.notCompatible.step2")}`}</p>
                </StyledNoAllowInstall>
            );
        } else {
            return (
                <>
                    <div>
                        <p> {`${i18n.t("onboarding.firefox.step1")}`}</p>
                        <WrapperIcon>
                            <MoreVertOutlinedIcon />
                        </WrapperIcon>
                    </div>
                    <div>
                        <p>{`${i18n.t("onboarding.firefox.step2")}`}</p>
                        <WrapperIcon>
                            <AddToHomeScreenOutlinedIcon />
                        </WrapperIcon>
                    </div>
                    <div>
                        <p>{`${i18n.t("onboarding.step3")}`}</p>
                    </div>
                </>
            );
        }
    };
    return (
        <Dialog
            PaperProps={{ sx: { position: "fixed", top: 115, m: 0 } }}
            fullWidth={true}
            open={isOpen}
            TransitionComponent={Transition}
            keepMounted
            style={{
                height: "100%",
                overflow: "hidden",
            }}
        >
            <StyledHeader>
                <h3>{`${i18n.t("installApp")}`}</h3>
                <StyledButtonClose
                    onClick={() => {
                        setOpen(false);
                    }}
                >
                    <CloseIcon />
                </StyledButtonClose>
            </StyledHeader>

            <StyledContent>{renderOnBoarding()}</StyledContent>
        </Dialog>
    );
};

const StyledHeader = styled.div`
    display: flex;
    border-bottom: 1px solid #e5e5e5;
    > h3 {
        width: 100%;
        margin-left: 1rem;
        font-size: 16px;
    }
    > button {
        margin-left: auto;
    }
`;

const StyledContent = styled.div`
    font-size: 14px;
    margin-left: 1rem;
    margin-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    text-align: left;
    > p {
        width: 90%;
    }
    > div {
        display: flex;
        align-items: center;
    }
`;

const StyledButtonClose = styled.button`
    font-size: 16px;
    align-items: center;
    justify-content: end;
    cursor: pointer;
    border: 0;
    background-color: transparent;
    :hover {
        opacity: 0.5;
    }
`;

const WrapperIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ebebeb;
    margin-left: 1rem;
`;

const StyledNoAllowInstall = styled.div`
    display: flex;
    flex-direction: column;
    > p {
        width: 100%;
    }
`;

export default OnBoarding;
