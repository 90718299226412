import { useEffect, useState } from "react";

type Props = {
    trigger: number;
};

const Downloader = ({ trigger }: Props) => {
    const [deferredPrompt, setDeferredPrompt] = useState<Event | null>(null);

    useEffect(() => {
        const handleBeforeInstallPrompt = (e: Event) => {
            e.preventDefault();
            setDeferredPrompt(e);
        };

        //This event is automatically triggered once a page is loaded
        //only if app has never been installed.
        //It will never be triggered if app has already been installed.
        window.addEventListener(
            "beforeinstallprompt",
            handleBeforeInstallPrompt
        );

        //Don't know why it constantly raises uncaught exception
        //Hiding it from user console by using .catch
        const handleClickInstall = () => {
            (deferredPrompt as any).prompt().catch((err: any) => {
                return;
            });
        };

        if (trigger && deferredPrompt) {
            handleClickInstall();
        }
    }, [deferredPrompt, trigger]);

    return null;
};

export default Downloader;
