import styled from "styled-components";
import { BaseButton } from "./BaseButton";

type Props = {
    handleValidate: () => void;
    children: JSX.Element;
    color?: string;
    alt?: string;
};

const ValidateButton = ({ handleValidate, children, ...rest }: Props) => {
    return (
        <StyledValidateButton
            onClick={handleValidate}
            alt="validate"
            tabIndex={0}
            data-testid="btn-validate"
            {...rest}
        >
            {children}
        </StyledValidateButton>
    );
};

const StyledValidateButton = styled(BaseButton)`
    box-shadow: ${(props) => props.theme.button.boxShadow};
    background-color: ${(props) => props.theme.validateButton.backgroundColor};
    color: ${(props) =>
        props.color ? props.color : props.theme.validateButton.fontColor};
    :hover {
        background-color: ${(props) =>
            props.theme.validateButton.hoverBackgroundColor};
        color: ${(props) => props.theme.validateButton.backgroundColor};
    }
`;

export default ValidateButton;
