import { Error as ErrorEnum } from "types/voteEnum";

export interface DisplayedError {
    body: string;
    header: string;
}

export class HTTPResponseError extends Error {
    response: Response;

    constructor(response: Response) {
        super(`HTTP Error Response: ${response.status} ${response.statusText}`);
        this.response = response;
    }
}

export class NotFoundError extends Error implements DisplayedError {
    constructor(message?: string) {
        super(message);
        this.name = ErrorEnum.NOT_FOUND;
        this.header = "404";
        this.body = "errorMessagePage404";
    }
    body: string;
    header: string;
}

export class AuthError extends Error implements DisplayedError {
    constructor(message?: string) {
        super(message);
        this.name = ErrorEnum.AUTH;
        this.header = "errorUnknown";
        this.body = "errorAuth";
    }
    body: string;
    header: string;
}

export class UnknownError extends Error implements DisplayedError {
    constructor(message?: string) {
        super(message);
        this.name = ErrorEnum.UNKNOWN;
        this.header = "errorUnknown";
        this.body = "errorMessagePage50x";
    }
    body: string;
    header: string;
}

export class NetworkError extends Error implements DisplayedError {
    constructor(message?: string) {
        super(message);
        this.name = ErrorEnum.NETWORK;
        this.header = "errorUnknown";
        this.body = "errorMessageNetwork";
    }
    body: string;
    header: string;
}
