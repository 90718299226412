import { useEffect, useState } from "react";
import { buildGlobalStylesComponent } from "../globalStyles";
import { getSubdomain } from "services/urlService";
import { getCustomizationLocalStorageWithExpiry } from "services/customizationService";
import { CUSTOMIZATION_SET_EVENT } from "constants/constants";

const GlobalStylesWrapper = () => {
    const subdomain = getSubdomain();
    const [currentTheme, setCurrentTheme] = useState<string | null>(
        getCustomizationLocalStorageWithExpiry()
    );

    useEffect(() => {
        if (subdomain && !currentTheme) {
            window.addEventListener(CUSTOMIZATION_SET_EVENT, () => {
                const customization = getCustomizationLocalStorageWithExpiry();
                if (customization) {
                    setCurrentTheme(customization);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const GlobalStyle = buildGlobalStylesComponent({
        overrides: currentTheme,
    });

    return <GlobalStyle />;
};

export default GlobalStylesWrapper;
