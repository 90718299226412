import React, { useState } from "react";
import styled, { ThemeProvider } from "styled-components";

import { DisplayType, ColorRemote, ButtonType } from "types/voteEnum";

import ScreenRemoteQuizzbox from "./ScreenRemote/ScreenRemoteQuizzbox";
import KeyboardQuizzbox from "./Keyboard/KeyboardQuizzbox";
import { orange, white } from "./theme";

type Props = {
    onValidate: (result: string) => any;
    displayType: DisplayType;
    color: ColorRemote;
    lang: string;
    isSecret: boolean;
    answerCanBeModified: boolean;
    buttonType: ButtonType;
    remoteMaxNumberButton: number;
    minResponseLength?: number;
    maxResponseLength?: number;
    valueCanAppearMultipleTimes: boolean;
};

const VirtualRemoteQuizzbox = ({
    onValidate,
    displayType,
    color,
    isSecret,
    answerCanBeModified,
    buttonType,
    remoteMaxNumberButton,
    minResponseLength,
    maxResponseLength,
    valueCanAppearMultipleTimes,
}: Props) => {
    const [answer, setAnswer] = useState("");
    const [hasResponseBeenValidated, setHasResponseBeenValidated] =
        useState(false);

    const CALL_RESULT_MAX_LENGTH = 16;
    const NUMERIC_RESULT_MAX_LENGTH = 16;
    const MINUS = "-";
    const COMMA = ".";

    const handleValidate = () => {
        const lastValue = answer[answer.length - 1];

        if (lastValue === COMMA) {
            return;
        }

        if (!answer) {
            return;
        }

        if (
            DisplayType.OTHER === displayType &&
            minResponseLength !== undefined
        ) {
            if (answer.length < minResponseLength) {
                return;
            }
        }

        setHasResponseBeenValidated(true);

        onValidate(answer);

        if (isSecret) {
            setAnswer("");
        }
    };

    const onReset = () => {
        //It's not possible to modified result is answerCanBeModified = false
        if (!answerCanBeModified) {
            return;
        }

        setAnswer("");
        setHasResponseBeenValidated(false);
    };

    const onButtonClick = (value: any) => {
        switch (displayType) {
            case DisplayType.CALL:
                if (answer.length >= CALL_RESULT_MAX_LENGTH) {
                    return;
                }

                if (value === MINUS || value === COMMA) {
                    return;
                }

                if (hasResponseBeenValidated) {
                    setHasResponseBeenValidated(false);
                    setAnswer(value);
                } else {
                    setAnswer(answer + value);
                }

                break;
            case DisplayType.NUMERIC:
                if (answer.length >= NUMERIC_RESULT_MAX_LENGTH) {
                    return;
                }

                if (answer.length >= 1 && "-" === value) {
                    return;
                }

                // The comma cannot be at the first position
                if (0 === answer.length && value === COMMA) {
                    return;
                }

                // The comma cannot be duplicated
                if (value === COMMA && answer.includes(COMMA)) {
                    return;
                }

                // It's not possible to concat minus and comma
                if (answer === MINUS && value === COMMA) {
                    return;
                }

                if (hasResponseBeenValidated) {
                    setHasResponseBeenValidated(false);
                    setAnswer(value);
                } else {
                    setAnswer(answer + value);
                }
                break;
            case DisplayType.OTHER:
                if (value === MINUS || value === COMMA) {
                    return;
                }

                // Impossible to duplicate if valueCanAppearMultipleTimes = false
                if (!valueCanAppearMultipleTimes) {
                    if (value && answer.includes(value)) {
                        return;
                    }
                }

                if (hasResponseBeenValidated) {
                    setHasResponseBeenValidated(false);
                    setAnswer(value);
                    return;
                }

                //When MaxRespLength = 1, click on another value for replace the current response
                if (1 === maxResponseLength) {
                    setAnswer(value);
                    return;
                }

                if (maxResponseLength && answer.length >= maxResponseLength) {
                    return;
                }

                setAnswer(answer + value);
                break;
            default:
                throw console.error(
                    `Invalid displayType provided : ${displayType}`
                );
        }
    };

    return (
        <ThemeProvider theme={ColorRemote.ORANGE === color ? orange : white}>
            <Wrapper>
                <ScreenRemoteQuizzbox
                    answer={answer}
                    hasResponseBeenValidated={hasResponseBeenValidated}
                />
                <KeyboardQuizzbox
                    displayType={displayType}
                    handleValidate={handleValidate}
                    onReset={onReset}
                    onButtonClick={onButtonClick}
                    buttonType={buttonType}
                    remoteMaxNumberButton={remoteMaxNumberButton}
                />
            </Wrapper>
        </ThemeProvider>
    );
};

const Wrapper = styled.div`
    border-radius: 16px;
    padding: 8px 8px 16px;
    width: 208px;
    border: ${(props) => props.theme.border} 1px solid;
    background: ${(props) => props.theme.backgroundColor};
    margin: auto;
`;

export default VirtualRemoteQuizzbox;
