import React, { useState, ReactElement } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { CSS_VARIABLES } from "enums/cssVariables";

type Props = {
    children: ReactElement;
};

const STORAGE_SHOULD_SHOW_PROXIES = "shouldShowProxies";

const Accordion = ({ children }: Props) => {
    const [shouldShowChildren, setShouldShowChildren] = useState<boolean>(
        localStorage.getItem(STORAGE_SHOULD_SHOW_PROXIES) !== null
            ? "true" === localStorage.getItem(STORAGE_SHOULD_SHOW_PROXIES)
            : true
    );

    const i18n = useTranslation();

    const handleAccordionToggle = () => {
        setShouldShowChildren(!shouldShowChildren);
        localStorage.setItem(
            STORAGE_SHOULD_SHOW_PROXIES,
            (!shouldShowChildren).toString()
        );
    };
    const handleEnterAccessibility = (
        e: React.KeyboardEvent<HTMLDivElement>
    ) => {
        if ("Enter" === e.key) {
            handleAccordionToggle();
        }
    };

    return (
        <WrapperToggle>
            <div>
                <StyledToggleText
                    onClick={handleAccordionToggle}
                    tabIndex={0}
                    onKeyDown={handleEnterAccessibility}
                >
                    <>
                        {shouldShowChildren ? (
                            <ArrowUpwardIcon
                                style={{
                                    fontSize: "14px",
                                    marginRight: "0.5rem",
                                }}
                            />
                        ) : (
                            <ArrowDownwardIcon
                                style={{
                                    fontSize: "14px",
                                    marginRight: "0.5rem",
                                }}
                            />
                        )}
                        {i18n.t(
                            shouldShowChildren ? "hideProxies" : "showProxies"
                        )}
                    </>
                </StyledToggleText>

                {shouldShowChildren ? children : null}
            </div>
        </WrapperToggle>
    );
};

const WrapperToggle = styled.div`
    outline: none;
    :focus-visible {
        outline: solid 3px var(${CSS_VARIABLES.PRIMARY_BUTTON_BACKGROUND_COLOR});
    }
`;

const StyledToggleText = styled.div`
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
`;

export default Accordion;
