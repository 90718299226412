import { VoteStatus } from "../useVoteState";

const getGlobalStatus = (
    individualStatusList: Array<VoteStatus>
): VoteStatus => {
    if (individualStatusList.some((status) => VoteStatus.Error === status)) {
        return VoteStatus.Error;
    }

    if (individualStatusList.some((status) => VoteStatus.Loading === status)) {
        return VoteStatus.Loading;
    }

    if (
        individualStatusList.some(
            (status) => VoteStatus.PendingValidation === status
        )
    ) {
        return VoteStatus.PendingValidation;
    }

    if (
        individualStatusList.every((status) => VoteStatus.NotAllowed === status)
    ) {
        return VoteStatus.NotAllowed;
    }

    // if at least one status is "Idle" and the others are "Idle" or "NotAllowed"
    if (
        individualStatusList.some((status) => status === VoteStatus.Idle) &&
        individualStatusList.every(
            (status) =>
                status === VoteStatus.NotAllowed || status === VoteStatus.Idle
        )
    ) {
        return VoteStatus.Idle;
    }

    // if at least one status is "Success" and the others are "Success" or "NotAllowed"
    if (
        individualStatusList.some((status) => status === VoteStatus.Success) &&
        individualStatusList.every(
            (status) =>
                VoteStatus.Success === status ||
                VoteStatus.NotAllowed === status
        )
    ) {
        return VoteStatus.Success;
    }

    // At this point, all the other cases have been handled
    return VoteStatus.Incomplete;
};

export default getGlobalStatus;
