import styled from "styled-components";
import { DragAndDropDiv } from "./DragAndDropDiv";
import img from "assets/drag-and-drop.svg";

type Props = {
    text: string;
    provided: any;
    getStyle: React.CSSProperties;
    innerRef: any;
};

const OptionButton = ({ innerRef, provided, text, getStyle }: Props) => {
    return (
        <DragAndDropDiv
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={innerRef}
            style={getStyle}
        >
            <StyledSpan>
                <DnDIcon src={img} />
            </StyledSpan>
            <StyledSpan>{text}</StyledSpan>
        </DragAndDropDiv>
    );
};

const DnDIcon = styled.img`
    width: 17px;
    height: auto;
`;
const StyledSpan = styled.span`
    margin-left: 1rem;
`;

export default OptionButton;
