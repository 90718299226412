import { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";

import { useUser } from "components/helper/userContext";
import { useSession } from "components/helper/sessionContext";

import {
    getCurrentUser,
    getSessionInformation,
    loginAnonymously,
} from "components/helper/callApi";
import { AuthError, UnknownError } from "services/errors";
import { useHandleErrors } from "./common/handleErrors";

import Spinner from "../common/Spinner";
import { ValidateButton } from "../common/ValidateButton";
import { ERROR } from "types/snackBar.constant";
import { CSS_VARIABLES } from "enums/cssVariables";
import { LS_AUTH_TOKEN_KEY } from "constants/constants";

type Props = {
    codeOnline: string;
};

const UserAnonymousLogin = ({ codeOnline }: Props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    const i18n = useTranslation();
    const { setUser } = useUser();
    const { setSession } = useSession();
    const { enqueueSnackbar } = useSnackbar();
    const { handleCurrentUserError, handleSessionError } = useHandleErrors();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await loginAnonymously(codeOnline);
                if (res.ok) {
                    const { token } = await res.json();
                    if (!token) {
                        setError(new UnknownError());
                    }

                    localStorage.setItem(LS_AUTH_TOKEN_KEY, token);
                    setIsLoading(false);

                    const userResponse = await getCurrentUser();
                    if (userResponse.ok) {
                        const data = await userResponse.json();
                        setUser(data);
                        const sessionResponse = await getSessionInformation(
                            codeOnline
                        );

                        if (sessionResponse.ok) {
                            const sessionData = await sessionResponse.json();
                            setSession(sessionData);
                        } else {
                            handleSessionError(sessionResponse.status);
                        }
                    } else {
                        handleCurrentUserError(userResponse.status);
                    }
                } else {
                    if (res.status === 401) {
                        throw new AuthError();
                    } else {
                        throw new UnknownError();
                    }
                }
            } catch (error) {
                setError(new UnknownError());
                enqueueSnackbar(`${i18n.t("errorUnknown")}`, {
                    variant: ERROR,
                });
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [codeOnline]);

    if (error) {
        return (
            <WrapperError>
                <h2>{`${i18n.t("error")}`}</h2>
                <StyledErrorDiv>
                    {`${i18n.t("errorMessagePage50x")}`}
                </StyledErrorDiv>
                <Link to="/">
                    <ValidateButton>
                        <span>{`${i18n.t("backToHome")}`}</span>
                    </ValidateButton>
                </Link>
            </WrapperError>
        );
    }

    return (
        <>
            {isLoading ? (
                <SpinnerWrapper>
                    <Spinner />
                </SpinnerWrapper>
            ) : null}
        </>
    );
};

const SpinnerWrapper = styled.div`
    padding-bottom: 20px;
`;

const WrapperError = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    > h2 {
        font-size: 1.3rem;
        letter-spacing: 2px;
        margin-bottom: 0;
        font-weight: 500;
    }
`;

const StyledErrorDiv = styled.div`
    font-size: 1rem;
    padding: 1.5rem 1rem;
    color: var(${CSS_VARIABLES.PRIMARY_TEXT_COLOR});
    text-align: center;
`;

export default UserAnonymousLogin;
