export enum VoteStatus {
    PendingValidation = "pendingValidation",
    Idle = "idle",
    Incomplete = "incomplete",
    Loading = "loading",
    Success = "success",
    Error = "error",
    NotAllowed = "notAllowed",
}

export enum LoginStatus {
    PendingValidation = "pendingValidation",
    Idle = "idle",
    Success = "success",
}

export enum DisplayType {
    CALL = "call",
    NUMERIC = "numeric",
    OTHER = "other",
}

export enum ColorRemote {
    WHITE = "white",
    ORANGE = "orange",
}

export enum ButtonType {
    NUMBER = "number",
    LETTER = "letter",
}

export enum Error {
    NOT_FOUND = "errorNotFound",
    AUTH = "errorAuth",
    UNKNOWN = "errorUnknown",
    NETWORK = "errorNetwork",
}

export enum Theme {
    QUIZZBOX = "quizzbox",
    VOTEBOX = "votebox",
}
