import { CSS_VARIABLES } from "enums/cssVariables";
import styled from "styled-components";

const Spinner = () => {
    return <SpinnerComponent />;
};

const SpinnerComponent = styled.div`
    border: 10px solid #f3f3f3;
    border-top: 10px solid var(${CSS_VARIABLES.PRIMARY_BUTTON_BACKGROUND_COLOR});
    border-radius: 50%;
    width: 55px;
    height: 55px;
    margin: auto;
    animation: spin 1s linear infinite;
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

export default Spinner;
