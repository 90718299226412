import React, { useState } from "react";
import styled, { ThemeProvider } from "styled-components";

import { QuestionType } from "types/questionTypes";
import { white } from "./theme";

import ScreenRemoteVotebox from "./ScreenRemote/ScreenRemoteVotebox";
import KeyboardVotebox from "./Keyboard/KeyboardVotebox";

const ANSWER_MAX_LENGTH = 3;

type Props = {
    onValidate: (result: string) => any;
    answerCanBeModified: boolean;
    remoteMaxNumberButton: number;
    availableAnswers: Array<string>;
};

const VirtualRemoteVoteboxElectionUni = ({
    onValidate,
    remoteMaxNumberButton,
    availableAnswers,
}: Props) => {
    const [answer, setAnswer] = useState<string>("");
    const [hasResponseBeenValidated, setHasResponseBeenValidated] =
        useState<boolean>(false);

    const handleValidate = () => {
        if (hasResponseBeenValidated) {
            return;
        } else if (!answer) {
            return;
        } else if (!availableAnswers?.includes(answer)) {
            setAnswer("ERR");
        } else {
            onValidate(answer);
            setAnswer("OK");
            setHasResponseBeenValidated(true);
        }
    };

    const onReset = () => {
        setAnswer("");
        setHasResponseBeenValidated(false);
    };

    const onButtonClick = (value: React.SetStateAction<string>) => {
        if (hasResponseBeenValidated) {
            setAnswer(value);
            setHasResponseBeenValidated(false);
            return;
        }

        if ("ERR" === answer) {
            setAnswer(value);
        }

        if (answer.length >= ANSWER_MAX_LENGTH) {
            return;
        }

        setAnswer(`${answer}${value}`);
    };

    return (
        <ThemeProvider theme={white}>
            <Wrapper>
                <ScreenRemoteVotebox
                    answer={answer}
                    hasResponseBeenValidated={hasResponseBeenValidated}
                />
                <KeyboardVotebox
                    handleValidate={handleValidate}
                    onReset={onReset}
                    onButtonClick={onButtonClick}
                    remoteMaxNumberButton={remoteMaxNumberButton}
                    typeVote={QuestionType.ELECTION}
                />
            </Wrapper>
        </ThemeProvider>
    );
};

const Wrapper = styled.div`
    border-radius: 16px;
    padding: 8px 8px 16px;
    width: 225px;
    border: ${(props) => props.theme.border} 1px solid;
    background: ${(props) => props.theme.backgroundColor};
    margin: auto;
`;

export default VirtualRemoteVoteboxElectionUni;
