import styled from "styled-components";
import ClearIcon from "@mui/icons-material/Clear";

import { BaseButton } from "./BaseButton";

type Props = {
    onReset: () => void;
    color?: string;
    alt?: string;
};

const ResetButton = ({ onReset, ...rest }: Props) => {
    return (
        <StyledCancelButton
            onClick={onReset}
            alt="reset"
            tabIndex={0}
            {...rest}
            data-testid="btn-reset"
        >
            <ClearIcon style={{ fontSize: "32px" }} />
        </StyledCancelButton>
    );
};

const StyledCancelButton = styled(BaseButton)`
    box-shadow: ${(props) => props.theme.button.boxShadow};
    background-color: ${(props) => props.theme.backgroundColor};
    color: ${(props) =>
        props.color ? props.color : props.theme.resetButton.fontColor};
    :hover {
        background-color: ${(props) => props.theme.button.hoverBackgroundColor};
    }
`;

export default ResetButton;
