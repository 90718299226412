import { useTranslation } from "react-i18next";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import styled from "styled-components";
import { CSS_VARIABLES } from "enums/cssVariables";

type Props = {
    handler?: () => void;
};

const SignatureLogoutButton = ({ handler }: Props) => {
    const i18n = useTranslation();

    return (
        <StyledLogoutButton onClick={handler}>
            <ArrowBackIosIcon />
            {`${i18n.t("editLoginInformation")}`}
        </StyledLogoutButton>
    );
};

const StyledLogoutButton = styled.button`
    padding: 6px 12px;
    margin: 2rem 0 0 3rem;
    display: flex;
    justify-content: start;
    align-items: center;
    cursor: pointer;
    border: 1px solid var(${CSS_VARIABLES.PRIMARY_BUTTON_BACKGROUND_COLOR});
    color: #fff;
    background-color: var(${CSS_VARIABLES.PRIMARY_BUTTON_BACKGROUND_COLOR});
    :focus-visible {
        outline: solid 2px var(${CSS_VARIABLES.PRIMARY_BUTTON_BACKGROUND_COLOR});
    }
    :hover {
        cursor: pointer;
        filter: brightness(0.8);
    }
`;

export default SignatureLogoutButton;
