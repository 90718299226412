import styled from "styled-components";

export const DragAndDropDiv = styled.div`
    border: 1px solid grey;
    border-radius: 3px;
    box-shadow: 0 1px 0 #091e4240;
    margin-bottom: 1rem;
    min-height: 3rem;
    word-wrap: break-word;
    display: flex;
    align-items: center;
    cursor: grab;
`;
