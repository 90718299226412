import { CSS_VARIABLES } from "enums/cssVariables";
import styled from "styled-components";

export const BaseProposal = styled.button`
    width: 100%;
    display: block;
    background: #ffffff;
    border: 1px solid grey;
    margin-bottom: 0.5rem;
    word-wrap: break-word;
    :hover {
        cursor: pointer;
    }
    :focus-visible {
        outline: solid 3px var(${CSS_VARIABLES.PRIMARY_BUTTON_BACKGROUND_COLOR});
    }
`;
