import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { Language, LANGUAGES } from "../../Language";

const LanguageSelector = () => {
    const { i18n } = useTranslation();

    let changeLanguage = (event: ChangeEvent<HTMLSelectElement>) => {
        const selectedLanguage =
            event.target.value.toLocaleUpperCase() as keyof typeof Language;
        if (Language[selectedLanguage]) {
            i18n.changeLanguage(Language[selectedLanguage]);
        }
    };

    return (
        <div>
            <div>
                <select
                    name="language"
                    value={i18n.language}
                    onChange={changeLanguage}
                    aria-label="Language"
                >
                    {LANGUAGES.map((lang, index) => (
                        <option key={index} value={lang.key}>
                            {lang.description}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default LanguageSelector;
