import styled from "styled-components";

import { BasicButton } from "components/common/BasicButton";
import i18n from "i18n/i18n";
import LogoutWrapper from "components/common/LogoutWrapper";

type Props = {
    handler?: () => void;
};

const LogoutButton = ({ handler }: Props) => {
    return (
        <LogoutWrapper>
            <LogOutBtn onClick={handler}>{`${i18n.t("logOut")}`}</LogOutBtn>
        </LogoutWrapper>
    );
};

const LogOutBtn = styled(BasicButton)`
    padding: 0.25rem;
    font-size: 0.75rem;
    border: 1px solid #ccc;
    background-color: white;
    color: black;
    :hover {
        cursor: pointer;
        filter: brightness(0.8);
    }
`;

export default LogoutButton;
