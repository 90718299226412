import { EnumDescriptor } from "types/enumDescriptor";

export enum Language {
    FR = "fr",
    EN = "en",
    DE = "de",
    ES = "es",
}

export const LANGUAGES: Array<EnumDescriptor> = [
    { key: Language.FR, description: "Français" },
    { key: Language.DE, description: "Deutsch" },
    { key: Language.EN, description: "English" },
    { key: Language.ES, description: "Spanish" },
];
