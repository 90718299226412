import React, { useState, useContext, createContext } from "react";

type SubdomainType = {
    isDone: boolean;
    setIsDone: React.Dispatch<React.SetStateAction<boolean>>;
};

type SubdomainProviderProps = {
    children: React.ReactNode;
};

const Subdomain = createContext<SubdomainType>({
    isDone: false,
    setIsDone: () => {},
});

export const useSubdomain = () => useContext(Subdomain);

export const SubdomainProvider = ({ children }: SubdomainProviderProps) => {
    const [isDone, setIsDone] = useState<boolean>(false);

    return (
        <Subdomain.Provider value={{ isDone, setIsDone }}>
            {children}
        </Subdomain.Provider>
    );
};
